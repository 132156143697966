<fap-header>
    <div class="add_circle mr-2">
        <mat-form-field>
            <mat-label style="top: -2px; position: relative;">{{'forms.formTypes' | translate}}</mat-label>
            <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="formTypes" [placeholder]="'forms.formTypes' | translate">
                <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #personFilter [placeholder]="'forms.formTypes' | translate" (keydown.enter)="$event.stopPropagation()">
                </mat-form-field>
                <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                    <i class="fa fa-search f-20"></i>
                </mat-label>
                <div class="input-group-append pl-1">
                    <span class="input-group-text">
                        <i class="icofont icofont icofont-plus" (click)="addNewFormType(); mys.close();"></i>
                     </span>
                </div>
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let type of formTypes | filter : personFilter.value" [value]="type.id" (click)="editFormType(type?.id);">
                    <span class="mat-option-text">
                    <div class="form_type_name name" [attr.data-formtype]="type?.uniqueid">
                        {{ type?.name }}
                    </div>
                    <div class="actions">
                        <i class="fa fa-edit center f-20" (click)="editFormType(type?.id);"></i>
                        <i class="fa fa-trash center f-20 ml-1" (click)="deleteFormType(type?.id); $event.stopPropagation()"></i>
                    </div>
                    </span>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field>
    </div>
</fap-header>
<div class="container-fluid ter-container nc d-flex">
    <!-- <div class="form_types_list">
        <div class="form_type" *ngFor="let type of formTypes; let i = index;" (click)="displayFormType(type)">
            <span *ngIf="type?.name_t && type?.name_t.id">{{ type?.name_t[langString].trim() !== '' ? type?.name_t[langString] : type?.name  }}</span>
            <span *ngIf="!type?.name_t">{{ type?.name }}</span>

            <div class="delete" *ngIf="!type?.locked" (click)="deleteFormType(type.id); $event.preventDefault(); $event.stopPropagation()">
                <span><i class="fa fa-trash"></i></span>
            </div>
        </div>
    </div> -->

    <div class="form_editor" *ngIf="type || newType">
        <div class="form_fields">
            <!-- <button [disabled]="type?.locked" class="btn" (click)="edit('name_t')">{{"forms.name" | translate}}</button> -->
            <button [disabled]="type?.locked" class="btn" (click)="edit('info')">{{"forms.info" | translate}}</button>
            <div style="position: relative; display: inline-block;">
                <button [disabled]="type?.locked" class="btn">{{"forms.image" | translate}}</button>
                <fap-image-combo [hideInput]="true" [group]="''" (addImage)="addImage($event)" (addNewImage)="addNewImage($event)"></fap-image-combo>
            </div>
            <!-- <mat-slide-toggle color="#34A59A" [checked]="bgImg" [(ngModel)]="bgImg" (change)="onToggleImage($event)">{{"forms.image" | translate}}</mat-slide-toggle> -->
            <button [disabled]="type?.locked" class="btn" (click)="showFields()">{{"forms.addField" | translate}}</button>
            <button [disabled]="type?.locked" class="btn" (click)="showAddGroupModal()">{{"forms.addGroup" | translate}}</button>
            <button [disabled]="type?.locked" class="btn" (click)="showFormCssModal()">{{"forms.formConfig" | translate}}</button>
            <button [disabled]="!isSubmitEnabled || type?.locked" class="btn" (click)="updateFormType()">{{ 'translations.save' | translate }}</button>
        </div>
        <!-- <p class="form_preview_text">{{ "forms.formTemplate" | translate }}</p> -->
        <div class="form_preview" [ngStyle]="{ 'width': defaultSize === 'pc' ? type?.form_css?.pc?.width : defaultSize === 'tablet' ? type?.form_css?.tablet?.width : type?.form_css?.mobile?.width, 'height': defaultSize === 'pc' ? type?.form_css?.pc?.height : defaultSize === 'tablet' ? type?.form_css?.tablet?.height : type?.form_css?.mobile?.height }">
            <div class="form_top_row d-flex justify-content-between">
                <div class="tabs_btns">
                    <div class="screen_fields">
                        <button class="btn" [ngClass]="{'active': defaultSize === 'pc'}" (click)="defaultSize = 'pc'; displayFormType(type);">{{"forms.pc" | translate}}</button>
                        <button class="btn" [ngClass]="{'active': defaultSize === 'tablet'}" (click)="defaultSize = 'tablet'; displayFormType(type);">{{"forms.tablet" | translate}}</button>
                        <button class="btn" [ngClass]="{'active': defaultSize === 'mobile'}" (click)="defaultSize = 'mobile'; displayFormType(type);">{{"forms.mobile" | translate}}</button>
                    </div>
                </div>
                <div class="preview_btn" *ngIf="type?.id">
                    <button class="btn" [disabled]="isSubmitEnabled" (click)="previewForm()">{{ 'forms.preview' | translate }}</button>
                </div>
            </div>
            <div class="name_and_unique d-flex justify-content-between">
                <div class="form_name">
                    {{formName ? formName : namePlaceholder}} <i (click)="edit('name_t')" *ngIf="!type?.locked" class="fa fa-edit center f-16 ml-2"></i>
                </div>
                <div class="form_uniqueid">
                    ID: {{this.type?.uniqueid}} <i (click)="editUniqueid(this.type?.uniqueid)" *ngIf="!type?.locked" class="fa fa-edit center f-16 ml-2"></i>
                </div>
            </div>
            <div class="form_body d-flex">
                <div *ngIf="bgImg" class="form_pic" style="position: relative;" [ngClass]="'col-' + (defaultSize === 'pc' ? type?.form_css?.pc?.banner?.cols : defaultSize === 'tablet' ? type?.form_css?.tablet?.banner?.cols : type?.form_css?.mobile?.banner?.cols) + ' row-' + (defaultSize === 'pc' ? type?.form_css?.pc?.banner?.rows : defaultSize === 'tablet' ? type?.form_css?.tablet?.banner?.rows : type?.form_css?.mobile?.banner?.rows)">
                    <div class="img" *ngIf="bgImg && bgImg?.file && showImg">
                        <img [src]="mediaUrl + bgImg?.file" alt="">
                        <span class="cls" style="z-index: 2" *ngIf="!type?.locked" (click)="hideImg()"><i class="fa fa-times"></i></span>
                    </div>
                    <!-- <fap-image-combo [hideInput]="true" [group]="''" (addImage)="addImage($event)" (addNewImage)="addNewImage($event)"></fap-image-combo> -->
                </div>
                <div class="form_content">
                    <div class="info" (click)="edit('info')" *ngIf="showInfo && info" [innerHTML]="sanitizeHTML(info?.[langString])">
                        <span class="cls" *ngIf="!type?.locked" (click)="hideInfo()"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="fields_area row w-100 m-0" id="dropzone">
                        <ng-container *ngFor="let item of mixedFields; let i = index">
                          <div
                            [ngClass]="'col-' + (defaultSize === 'pc' ? item?.pc?.cols : defaultSize === 'tablet' ? item?.tablet?.cols : item?.mobile?.cols) + ' row-' + (defaultSize === 'pc' ? item?.pc?.rows : defaultSize === 'tablet' ? item?.tablet?.rows : item?.mobile?.rows)"
                            [ngStyle]="{ 'order': defaultSize === 'pc' ? item?.pc?.pos : defaultSize === 'tablet' ? item?.tablet?.pos : item?.mobile?.pos }"
                            class="field"
                          >
                            <div *ngIf="item?.field" class="area" (click)="editCss(item)" draggable="true"
                              (dragstart)="onDragStart($event, i)"
                              (dragover)="onDragOver($event)"
                              (drop)="onDrop($event, i)">
                              <span class="text">{{ item?.field?.name_t ? getTranslation(item?.field?.name_t) : item?.field?.name }} <span>{{item?.field?.uniqueid}}</span></span>
                              <span class="cls" *ngIf="!type?.locked" (click)="removeFormFieldType(item?.id, item?.field); $event.preventDefault(); $event.stopPropagation();"><i class="fa fa-times"></i></span>
                            </div>
                            <div *ngIf="!item?.field" class="group">
                              <div (click)="editGroupCss(item)" draggable="true"
                                (dragstart)="onDragStart($event, i)"
                                (dragover)="onDragOver($event)"
                                (drop)="onDrop($event, i)"><span>{{ item?.name }}</span> <span class="cls" *ngIf="!type?.locked" (click)="removeGroup(item); $event.preventDefault(); $event.stopPropagation();"><i class="fa fa-times"></i></span></div>
                              <ng-container *ngIf="item?.nestedFields">
                                <!-- {{ item?.nestedFields | json }} -->
                                <div class="fields_area p-0 w-100">
                                  <ng-container *ngFor="let nestedItem of item?.nestedFields; let j = index">
                                    <div
                                      [ngClass]="'col-' + (defaultSize === 'pc' ? nestedItem?.pc?.cols : defaultSize === 'tablet' ? nestedItem?.tablet?.cols : nestedItem?.mobile?.cols) + ' row-' + (defaultSize === 'pc' ? nestedItem?.pc?.rows : defaultSize === 'tablet' ? nestedItem?.tablet?.rows : nestedItem?.mobile?.rows)"
                                      [ngStyle]="{ 'order': defaultSize === 'pc' ? item?.pc?.pos : defaultSize === 'tablet' ? item?.tablet?.pos : item?.mobile?.pos }"
                                      class="field"
                                      draggable="true"
                                      (dragstart)="onDragStart($event, j, true, i)"
                                      (dragover)="onDragOver($event)"
                                      (drop)="onDrop($event, j, true, i)"
                                    >
                                      <div *ngIf="nestedItem?.field" class="area" (click)="editCss(nestedItem)">
                                        <span class="text">{{ nestedItem?.field?.name_t ? getTranslation(nestedItem?.field?.name_t) : nestedItem?.field?.name }} <span>{{nestedItem?.field?.uniqueid}}</span></span>
                                        <span class="cls" *ngIf="!type?.locked" (click)="removeFormFieldType(nestedItem?.id, nestedItem?.field); $event.preventDefault(); $event.stopPropagation();"><i class="fa fa-times"></i></span>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                    </div>                                              
                </div>
            </div>
        </div>
    </div>

    <fap-modal >

    </fap-modal>

<fap-modal #editFieldModal
    [inputPrimaryButtonInterface]="editFieldButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="editFieldButtonSecondaryInterface"
    [inputTitle]="selectedEditFieldLabel">
<form>
    <div class="form-group">
        <div class="row align-items-baseline">
            <div class="col-12">
                <div class="input-group align-items-baseline w-100">
                    <!-- <mat-form-field class="w-100">
                        <mat-label>{{ selectedEditField }}</mat-label>
                        <input matInput (focusout)="updateTextField(selectedEditField, $event)" [value]="type? type[selectedEditField] : ''">
                    </mat-form-field> -->

                    <!-- <mat-form-field class="w-100" (click)="addEditFieldTranslation()" *ngIf="selectedEditField === 'name'">
                        <mat-label class="col-form-label">{{ selectedEditField }}</mat-label>
                        <input matInput readonly #nameInput1 [value]="type? type[selectedEditField] : ''">
                    </mat-form-field> -->

                    <mat-form-field class="w-100" (click)="addEditFieldTranslation(selectedEditFieldLabel)">
                        <mat-label class="col-form-label">{{ selectedEditFieldLabel }}</mat-label>
                        <input matInput readonly #nameInput1 [value]="type ? type[selectedEditField] ? type[selectedEditField][langString] : '' : ''">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</form>
</fap-modal>

<fap-modal #editUniqueidModal
    [inputPrimaryButtonInterface]="editUniqueidButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="editUniqueidButtonSecondaryInterface"
    [inputTitle]="'Unique ID'">
<form [formGroup]="addEditUniqueidForm" class="col-12 p-0">
    <div class="form-group">
        <div class="row align-items-baseline">
            <div class="col-12">
                <div class="input-group align-items-baseline w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'Unique ID' }}</mat-label>
                        <input matInput placeholder="Unique ID" formControlName="uniqueid">
                      </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</form>
</fap-modal>

<fap-modal #fieldsModal
[inputPrimaryButtonInterface]="fieldModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="fieldModalButtonSecondaryInterface"
inputTitle="Fields">
    <div class="w-100">
        <chips 
            [items]="globalRegistry?.systemData?.attributes" 
            [selectedItems]="selectedFields"
            [showFieldUniqueId]="true"
            [label]="'attribute.attributes' | translate"
            (updatedItems)="collectAttributes($event)"
            [addEnabled]="true"
            [isEditable]="true"
            (customEditEmit)="addEditAttribute($event)"
            (editItem)="addEditAttribute($event)"
            (showAddModal)="addEditAttribute()"
        >
        </chips>
    </div>
</fap-modal>

<fap-modal #addEditAttributePopup [inputTitle]="'forms.addEditFormFields' | translate" [inputIsMedium]="true">
    <form [formGroup]="addEditAttributeForm" class="col-12 p-0">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
                    <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput>
                    <mat-error *ngIf="addEditAttributeForm.controls['name'].errors?.required">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'forms.uniqueid' | translate }}</mat-label>
                    <input matInput formControlName="uniqueid" (input)="filterInput($event)">
                    <mat-error *ngIf="addEditAttributeForm.controls['uniqueid'].errors?.required">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="f.uniqueid.errors?.specialChars">
                        <div>Special characters are not allowed.</div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label class="col-form-label">{{ "notes.color" | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color"/>
                <mat-error *ngIf="addEditAttributeForm.controls['color'].errors?.required">
                    <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                </mat-error>
            </mat-form-field>
            <div class="col-6" style="position: relative; top: 10px;">
                <fap-image-combo [group]="''" (addImage)="addIcon($event)" [label]="'Icon'"></fap-image-combo>
        </div>
        </div>
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label>{{ 'attribute.valueType' | translate }}</mat-label>
                <mat-select required formControlName='value_type' (selectionChange)="validateInputType($event.value)">
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let type of valueTypes" [value]='type'>
                        <div class="attr-type-name">{{ type | titlecase }}</div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
            <div class="col-6">
                <mat-form-field style="max-width: 100%; margin-left: -1px;" *ngIf="addEditAttributeForm.controls['value_type'].value ==='number'" class="w-100">
                    <mat-label>Unit</mat-label>
                <mat-select class="select u" formControlName="unit" #unitsCombo>
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #unitFilter placeholder="Search">
                        </mat-form-field>
                        <!-- <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em> -->
                        <fap-round-button (clicked)="selectUnit()"></fap-round-button>
                    </div>
                    <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.units | filter : unitFilter.value">
                        <span class="mat-option-text">
                            <div class="type-name_title">
                                <span class="theme_color">{{parent.name}}</span>
                            </div>
                            <div class="d-flex text-center">
                                <action-buttons 
                                    [locked]="parent?.locked" 
                                    (editEmit)="selectUnit(parent)" 
                                    (deleteEmit)="onDeleteUnit(parent)"
                                ></action-buttons>
                            </div>
                        </span>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                            <div class="type-name">
                                <span>{{child.shortName}}</span>
                            </div>
                            <div class="d-flex text-center">
                                <action-buttons 
                                    [locked]="child?.locked" 
                                    (editEmit)="selectUnit(child)" 
                                    (deleteEmit)="onDeleteUnit(child)"
                                ></action-buttons>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </div>
                </mat-select>
                </mat-form-field>
                <mat-form-field style="max-width: 100%; margin-left: -1px;" *ngIf="addEditAttributeForm.controls['value_type'].value ==='entity'" class="w-100">
                    <mat-label>{{ 'attribute.entity' | translate }}</mat-label>
                <mat-select required formControlName='entity'>
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let entity of allEntities" [value]='entity.id'>
                        <div class="attr-type-name">
                            <span *ngIf="getTranslation(entity?.name_t) === '' || getTranslation(entity?.name_t) == null || getTranslation(type?.name_t) == undefined ">{{entity?.name}}</span>
                            <span *ngIf="getTranslation(entity?.name_t) !== ''">{{getTranslation(entity?.name_t)}}</span>
                            </div>
                    </mat-option>
                </div>
                </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'attribute.inputType' | translate }}</mat-label>
                    <mat-select required formControlName='input_type' (selectionChange)="validateChoice($event)">
                    <div class="scroll_wrap" style="padding-left: 10px;">
                    <mat-option style="height: 0!important;"></mat-option>
                        <mat-option style="margin: 0;" class='row' *ngFor="let type of filteredInputTypes" [value]='type'>
                            <div class="attr-type-name">{{ type | titlecase }}</div>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <ng-container *ngIf="selectedAttribute?.valueType === 'string' || selectedAttribute?.valueType === 'number' || selectedAttribute?.valueType === 'boolean'">
            <div class="w-100 mb-4" *ngIf="selectedAttribute?.inputType === 'multiselect' || selectedAttribute?.inputType === 'choice'">
                <ng-container>
                    <fap-field-choices [choices]="choices" (emitChoices)="updateChoices($event)"></fap-field-choices>
                </ng-container>
            </div>
        </ng-container>
        <div class="btns">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="selectedAttribute">
                    <i class="fa fa-trash" (click)="deleteAttribute(selectedAttribute?.id)"></i>
                </button>
            </div>
            <div class="action_btns d-flex justify-content-end mt-3">
                <button class="btn mr-2" (click)="submitAttribute()">{{ 'translations.save' | translate }}</button>
                <button class="btn" (click)="addEditAttributePopup.hideModal(); addEditAttributeForm.reset()">{{ 'translations.cancel' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #cssModal
    [inputPrimaryButtonInterface]="cssButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="cssButtonSecondaryInterface"
    [inputTitle]="'widget.config' | translate">
    <div class="row">
        <div class="col-12">
          <small>This field is ({{valueType}}/{{inputType}}) </small>
        </div>
        <ng-container *ngIf="defaultSize">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Columns</mat-label>
                    <mat-select [value]="getColValue()" (selectionChange)="changeCssItem(getColKey(), $event)">
                        <div class="scroll_wrap">
                            <mat-option *ngFor="let column of columnOptions" [value]="column">{{ column }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Rows</mat-label>
                    <mat-select [value]="getRowValue()" (selectionChange)="changeCssItem(getRowKey(), $event)">
                        <div class="scroll_wrap">
                            <mat-option *ngFor="let row of rowOptions" [value]="row">{{ row }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Position</mat-label>
                    <input matInput type="number" min="1" max="12" [value]="getPositionValue()" (focusout)="changeValue(getPositionKey(), $event)">
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Display</mat-label>
                    <mat-select [value]="getComponentValue()" (selectionChange)="changeCssItem(getComponentKey(), $event)">
                        <div class="scroll_wrap">
                            <mat-option *ngFor="let comp of filteredComponents" [value]="comp">{{ comp }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <div style="margin-top: 30px">
                <input id="default" type="checkbox" [checked]="isFieldValidated" (change)="isFieldValidated = !isFieldValidated">
                <label for="default" style="margin-left: 5px">{{ 'Validate' }}</label>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
            <button class="btn edit_field" (click)="addEditAttribute(selectedField?.field)">{{ 'Edit field' }} <i class="fa fa-edit center f-20"></i></button>
        </div>
    </div>
</fap-modal>

<fap-modal #groupModal
    [inputPrimaryButtonInterface]="groupCssButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="groupCssButtonSecondaryInterface"
    [inputTitle]="'widget.config' | translate">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <mat-form-field class="w-100">
                <mat-label>Name</mat-label>
                <input matInput type="text" [(ngModel)]="groupName">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <ng-container>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Columns</mat-label>
                    <mat-select [value]="getColValue()" (selectionChange)="changeCssItem(getColKey(), $event)">
                        <div class="scroll_wrap">
                            <mat-option *ngFor="let column of columnOptions" [value]="column">{{ column }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Rows</mat-label>
                    <mat-select [value]="getRowValue()" (selectionChange)="changeCssItem(getRowKey(), $event)">
                        <div class="scroll_wrap">
                            <mat-option *ngFor="let row of rowOptions" [value]="row">{{ row }}</mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Position</mat-label>
                    <input matInput type="number" min="1" max="12" [value]="getPositionValue()" (focusout)="changeValue(getPositionKey(), $event)">
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedAttribute)" 
        [prefix]="'FIELD_TYPE_'" 
        [translation]="translation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditFieldTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.type)"
        [prefix]="'FORM_TYPE_'" 
        [field]="selectedEditField"
        [translation]="fieldTranslation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateFieldTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditUnitModal 
inputTitle="{{ 'units.addEditUnit' | translate }}">
<form [formGroup]="unitForm" class="unit_form col-md-12 p-0" [ngClass]="{ 'locked-unit': currentUnit?.locked }">
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.name' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('name','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.shortName' | translate }}</mat-label>
            <input matInput formControlName="shortName">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('shortName','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.genre' | translate }}</mat-label>
            <!-- <input matInput formControlName="genre"> -->
            <mat-select required formControlName="genre">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #genreFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let genre of this.globalRegistry.genres | filter : genreFilter.value" [value]="genre?.indicator">
                    <span>{{ genre?.name }} ( <b>{{genre?.indicator}}</b> )</span>
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('genre','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group" *ngIf="globalRegistry?.systemData?.units">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentUnit && currentUnit">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #unitFilter placeholder="Search">
                </mat-form-field>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let parent of globalRegistry?.systemData?.units | filter : unitFilter.value" [value]="parent?.id">
                    {{ parent?.name }}
                </mat-option>
                </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('parent','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="mt-3 d-flex justify-content-end">
        <button class="btn mr-2" type="button" (click)="submit()">{{'translations.save' | translate}}</button>
        <button class="btn" type="button" (click)="addEditUnitModal.hideModal()">{{'translations.cancel' | translate}}</button>
    </div>
</form>
</fap-modal>

<fap-modal #criticalDeleteModal [inputTitle]="'confirmCriticalOperation' | translate" [inputIsLarge]="true" [showFooter]="false">
    <fap-critical-operation 
        [entityName]="deleteFieldName"
        (deleteEmit)="confirmRemoveFormFieldType(deleteFieldId); criticalDeleteModal.hideModal()"
    ></fap-critical-operation>
</fap-modal>

<fap-modal #previewModal 
    [inputTitle]="'Form preview'" 
    [inputIsLarge]="true" 
    [showFooter]="false" 
    [modalHeight]="defaultSize === 'pc' ? type?.form_css?.pc?.height : defaultSize === 'tablet' ? type?.form_css?.tablet?.height : type?.form_css?.mobile?.height"
    [modalWidth]="defaultSize === 'pc' ? type?.form_css?.pc?.width : defaultSize === 'tablet' ? type?.form_css?.tablet?.width : type?.form_css?.mobile?.width"
    (outputOnHide)="previewType = null; size = null"
    >
<div class="minus_margin prev_block" *ngIf="type?.id">
    <fap-attributes style="width: 100%"
        [size]="size"
        [btnsDisabled]="true"
        [entityId]="type?.id"
        [formTypeId]="type?.id"
        [entityInfo]="previewType"
        [isDynamic]="true"
        [objectId]="0">
    </fap-attributes>
</div>
</fap-modal>

<fap-modal #formConfigModal 
[inputTitle]="'Form configuration'" 
[showFooter]="false">
    <div class="form_config">
        <div class="main_form_config">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Form Height</mat-label>
                        <input matInput type="text" [(ngModel)]="height">
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Form width</mat-label>
                        <input matInput type="text" [(ngModel)]="width">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="image_config">
            <div class="row">
                <ng-container>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="form_pic w-100" style="position: relative;">
                            <mat-label class="col-form-label float">{{ 'Banner' }}</mat-label>
                            <div class="img" *ngIf="bgImg && bgImg?.file && showImg" style="position: relative;">
                                <img [src]="mediaUrl + bgImg?.file" alt="">
                                <span class="cls" style="z-index: 2" *ngIf="!type?.locked" (click)="hideImg()"><i class="fa fa-times"></i></span>
                            </div>
                            <div class="no-img" *ngIf="!bgImg"><i class="fa fa-upload" aria-hidden="true"></i> <span>{{ 'Add image' }}</span></div>
                            <fap-image-combo [hideInput]="true" [label]="'Banner'" [group]="''" (addImage)="addImage($event)" (addNewImage)="addNewImage($event)"></fap-image-combo>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Columns</mat-label>
                            <mat-select [value]="getColValue()" (selectionChange)="changeFormCssItem(getColKey(), $event)">
                                <div class="scroll_wrap">
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option *ngFor="let column of columnOptions" [value]="column">{{ column }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Rows</mat-label>
                            <mat-select [value]="getRowValue()" (selectionChange)="changeFormCssItem(getRowKey(), $event)">
                                <div class="scroll_wrap">
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option *ngFor="let row of rowOptions" [value]="row">{{ row }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="mt-3 d-flex justify-content-end">
            <button class="btn mr-2" type="button" (click)="submitFormConfig()">{{'translations.save' | translate}}</button>
            <button class="btn" type="button" (click)="formConfigModal.hideModal()">{{'translations.cancel' | translate}}</button>
        </div>
    </div>
</fap-modal>