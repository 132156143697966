<form [formGroup]="editValuesForm" class="col-12 p-0" [ngClass]="{disabled : selectedAttr?.enabled === false}">

    <ng-container *ngIf="field?.field?.value_type === 'image'">
        <ng-container *ngIf="field?.field?.input_type === 'single'">
            <div class="form-group w-100 m-0">
                <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                <fap-image-list [type]="'single'" [imageIds]="fieldImages" [group]="''" (deleteImage)="deleteNoteImage($event, field)" (addImage)="addImage($event, field, 'single')" [height]="height" [defaultSize]="screenSize"></fap-image-list>
            </div>
        </ng-container>
        <ng-container *ngIf="field?.field?.input_type === 'multiselect'">
            <div class="form-group w-100 m-0">
                <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                <fap-image-list [type]="'multiselect'" [imageIds]="fieldImages" [group]="''" (deleteImage)="deleteNoteImage($event, field)" (addImage)="addImage($event, field, 'multiselect')" [height]="height" [defaultSize]="screenSize"></fap-image-list>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'string'">
        <ng-container *ngIf="field?.field?.input_type === 'single'">
            <ng-container *ngIf="field?.[screenSize]?.component === 'input'">
                <div class="form-group w-100 m-0">
                    <mat-form-field class="w-100" (click)="handleTranslationClick(field)" [style.px]="getHeight(height)">
                        <mat-label class="col-form-label">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                        <input matInput formControlName="name" readonly #nameInput1 (change)="editValue.emit({value: editValuesForm.get('value'), type: 'value', validation: field?.validation})">
                    </mat-form-field>
                    <ng-container *ngFor="let errorField of errorFields">
                        <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                            <strong>{{ errorField?.error }}</strong>
                        </mat-error>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="field?.[screenSize]?.component === 'label'">
                <div class="form-group w-100 m-0" [style.px]="getHeight(height)">
                    <label style="margin-top: 15px;" [innerHTML]="sanitizeHTML(selectedAttr?.value?.input?.[langString])"></label>
                </div>
            </ng-container>
            <ng-container *ngIf="field?.[screenSize]?.component === 'button'">
                <div class="form-group w-100" [style.px]="getHeight(height)">
                    <button class="btn w-100 mt-0" ()> {{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }} </button>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type === 'choice'">
            <ng-container *ngIf="field?.[screenSize]?.component === 'radio'">
                <div class="choices_container">
                    <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                     <div class="custom_toggle d-flex">
                            <ng-container *ngFor="let choice of field?.field?.choices">
                                        <div class="choice_container d-flex" [style.px]="getHeight(height)">
                                            <div class="choice">
                                                <input type="radio" (change)="setChoice(choice, field)" name="value" formControlName="value" [value]="choice?.input">
                                                <span class="circ"></span>
                                            </div>
                                            <span>{{ getTranslation(choice?.name_t) }}</span>
                                        </div>
                                        <ng-container *ngFor="let errorField of errorFields">
                                            <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                                                <strong>{{ errorField?.error }}</strong>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>                          
            </ng-container>
            <ng-container *ngIf="field?.[screenSize]?.component !== 'radio'">
                            <div class="input-group w-100">
                                <mat-form-field class="w-100" [style.px]="getHeight(height)">
                                    <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                                    <mat-select panelClass="prevent-enter-close" formControlName="value" name="value" (selectionChange)="setValue(+$event.value, field)">
                                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #entityTextFilter placeholder="Search">
                                        </mat-form-field>
                                        <mat-label style="position: absolute; right: 35px; cursor: pointer;">
                                            <i class="fa fa-search f-20"></i>
                                        </mat-label>
                                    </div>
                                    <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let choice of field?.field?.choices" [value]="+choice.input">
                                            <span class="mat-option-text">
                                            <div class="name">{{ choice.name_t ? getTranslation(choice.name_t) : choice.name }}</div>
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngFor="let errorField of errorFields">
                                    <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                                        <strong>{{ errorField?.error }}</strong>
                                    </mat-error>
                                </ng-container>
                            </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type === 'multiselect'">
            <ng-container *ngIf="field?.[screenSize]?.component === 'checkbox'">
                <div class="choices_container">
                    <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                    <div class="custom_toggle d-flex">
                    <ng-container *ngFor="let choice of field?.field?.choices">
                            <div class="choice_container d-flex" [style.px]="getHeight(height)">
                                <div class="choice">
                                    <mat-checkbox [checked]="selectedChoices.includes(choice.id)" (change)="toggleChoice(choice.id)">
                                    <div style="position: relative; font-weight: normal">{{ getTranslation(choice?.name_t) }}</div>
                                    </mat-checkbox>
                                </div>
                                <ng-container *ngFor="let errorField of errorFields">
                                    <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                                        <strong>{{ errorField?.error }}</strong>
                                    </div>
                                </ng-container>
                            </div>
                    </ng-container>
                    </div>
                  </div> 
            </ng-container>
            <ng-container *ngIf="field?.[screenSize]?.component !== 'checkbox'">
                <!-- <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label> -->
                <chips 
                    [items]="field?.field?.choices"
                    [selectedItems]="selectedAttr.value.input"
                    [label]="field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name"
                    (updatedItems)="setValue($event, field)"
                    [addEnabled]="false"
                    [isEditable]="false"
                    [isDeleteable]="false"
                    (filterItem)="filterEntity(entityModel, $event)"
                    (emitScroll)="scrolledDown(entityModel)"
                >
                </chips>
                <ng-container *ngFor="let errorField of errorFields">
                    <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                        <strong>{{ errorField?.error }}</strong>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'number'">
        
        <ng-container *ngIf="field?.field?.input_type ==='single'">
            <div class="d-flex">
                <div class="w95 p-0">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" [style.px]="getHeight(height)">
                            <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                            <input type="number" style="text-align: right;" matInput formControlName="value" (change)="editValue.emit({value: +$event.target.value, type: 'value'})">
                            <ng-container *ngFor="let errorField of errorFields">
                                <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                                    <strong>{{ errorField?.error }}</strong>
                                </mat-error>
                            </ng-container>
                        </mat-form-field>
                    </div>
                </div>
                <div class="w5 p-0 mt-20" *ngIf="field?.field?.unit">
                    <div class="input-group w-100" style="position: relative; left: -1px;">
                        <mat-form-field class="w-100" [style.px]="getHeight(height)">
                            <mat-label>{{ " " }}</mat-label>
                            <input type="text" formControlName="unit" matInput readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type ==='range'">
            <div class="min_number">
                <div class="range_number_unit d-flex with_units">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" [style.px]="getHeight(height)">
                            <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }} {{'units.min' | translate}}</mat-label>
                            <input type="number" style="text-align: right;" matInput formControlName="min">
                        </mat-form-field>
                    </div>
                    <div class="divider"><div>-</div></div>
                    <div class="input-group w-100">
                        <mat-form-field class="w-100" [style.px]="getHeight(height)">
                            <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }} {{'units.max' | translate}}</mat-label>
                            <input type="number" style="text-align: right;" matInput formControlName="max" (change)="editValue.emit({value: {min: +editValuesForm.get('min').value ,max:+$event.target.value}, type: 'value', validation: field?.validation})">
                        </mat-form-field>
                    </div>
                    <div class="w5 p-0 mt-20" *ngIf="field?.field?.unit">
                        {{ field?.field?.unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                    </div>
                </div>
                <ng-container *ngFor="let errorField of errorFields">
                    <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                        <strong>{{ errorField?.error }}</strong>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type === 'multiselect'">
            <ng-container *ngIf="field?.[screenSize]?.component === 'checkbox'">
                <div class="choices_container">
                    <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                    <div class="custom_toggle d-flex">
                    <ng-container *ngFor="let choice of field?.field?.choices">
                            <div class="choice_container d-flex">
                                <div class="choice">
                                    <mat-checkbox [checked]="selectedChoices.includes(choice.id)" (change)="toggleChoice(choice.id)">
                        <div style="position: relative; font-weight: normal" class="mt-20">{{ getTranslation(choice?.name_t) }} 
                            <span *ngIf="field?.field?.unit">{{ field?.field?.unit | getUnit: globalRegistry?.systemData?.unitsMap }}</span>
                            </div>
                                    </mat-checkbox>
                                </div>
                            </div>
                    </ng-container>
                    </div>
                    <ng-container *ngFor="let errorField of errorFields">
                        <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                            <strong>{{ errorField?.error }}</strong>
                        </div>
                    </ng-container>
                  </div> 
            </ng-container>
            <ng-container *ngIf="field?.[screenSize]?.component !== 'checkbox'">
                <!-- <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label> -->
                <chips 
                    [items]="field?.field?.choices"
                    [selectedItems]="selectedAttr.value.input"
                    [label]="field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name + ' ' + field?.field?.unit | getUnit: globalRegistry?.systemData?.unitsMap"
                    (updatedItems)="setValue($event, field)"
                    [addEnabled]="false"
                    [isEditable]="false"
                    [isDeleteable]="false"
                    (filterItem)="filterEntity(entityModel, $event)"
                    (emitScroll)="scrolledDown(entityModel)"
                >
                </chips>
                <ng-container *ngFor="let errorField of errorFields">
                    <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                        <strong>{{ errorField?.error }}</strong>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type === 'choice'">
            <ng-container *ngIf="field?.[screenSize]?.component === 'radio'">
                <div class="choices_container">
                    <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                     <div class="custom_toggle d-flex">
                            <ng-container *ngFor="let choice of field?.field?.choices">
                                        <div class="choice_container d-flex" [style.px]="getHeight(height)">
                                            <div class="choice">
                                                <input type="radio" (change)="setChoice(choice, field)" name="value" formControlName="value" [value]="choice?.input">
                                                <span class="circ"></span>
                                            </div>
                                            <span>{{ getTranslation(choice?.name_t) }}</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container *ngFor="let errorField of errorFields">
                                    <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                                        <strong>{{ errorField?.error }}</strong>
                                    </div>
                                </ng-container>
                            </div>                          
            </ng-container>
            <ng-container *ngIf="field?.[screenSize]?.component !== 'radio'">
                            <div class="input-group w-100 chs">
                                <mat-form-field class="w-100" [style.px]="getHeight(height)">
                                    <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                                    <mat-select panelClass="prevent-enter-close" formControlName="value" name="value" (selectionChange)="setValue(+$event.value, field)">
                                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #entityTextFilter placeholder="Search">
                                        </mat-form-field>
                                        <mat-label style="position: absolute; right: 35px; cursor: pointer;">
                                            <i class="fa fa-search f-20"></i>
                                        </mat-label>
                                    </div>
                                    <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let choice of field?.field?.choices" [value]="choice.input">
                                            <span class="mat-option-text">
                                            <div class="name">{{choice.input}} - ( <small>{{ choice.name_t ? getTranslation(choice.name_t) : choice.name }} </small> )</div>
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngFor="let errorField of errorFields">
                                    <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                                        <strong>{{ errorField?.error }}</strong>
                                    </mat-error>
                                </ng-container>
                            </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'entity'">
        <ng-container *ngIf="field?.field?.input_type ==='single'">
            <div class="input-group w-100">
                <mat-form-field class="w-100" [style.px]="getHeight(height)">
                    <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                    <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" (infiniteScroll)="scrolledDown(entityModel)" [complete]="offset === entities.length" msInfiniteScroll [alwaysCallback]="true" formControlName="value" name="value" (selectionChange)="setValue(+$event.value, field)">
                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #entityTextFilter placeholder="Search" (keydown.enter)="filterEntity(entityModel, entityTextFilter.value); $event.stopPropagation(); mys.open()">
                        </mat-form-field>
                        <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterEntity(entityModel, entityTextFilter.value)">
                            <i class="fa fa-search f-20"></i>
                        </mat-label>
                    </div>
                    <div class="scroll_wrap" (scroll)="onScroll(entityModel, $event)">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option class="noteIcons" *ngFor="let entity of entities" [value]="entity.id">
                            <span class="mat-option-text">
                                <div class="name" *ngIf="(entity?.name || entity?.nameT || entity?.name_t) && !entity?.producer">
                                    {{ entity?.name_t ? getTranslation(entity?.name_t) : entity?.name }}
                                  </div>
                                  <div class="name prod_name" *ngIf="entity?.label?.producer" 
                                       [attr.data-producer]="entity?.label?.producer?.name" 
                                       [attr.data-unit]="entity | getProductUnit: globalRegistry?.systemData?.unitsMap">
                                    {{ entity?.label?.name_t ? getTranslation(entity?.label?.name_t) : entity?.label?.name }}
                                  </div>
                            <div class="name" *ngIf="entity?.doc_id">{{ entity?.doc_id }}</div>
                            <div class="name" *ngIf="entity?.display">{{ entity?.display }}</div>
                            <div class="name" *ngIf="entity?.sub_type">{{ entity?.sub_type }}</div>
                            </span>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngFor="let errorField of errorFields">
                    <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                        <strong>{{ errorField?.error }}</strong>
                    </mat-error>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type ==='multiselect' && entities.length">
            <div class="mt-2">
                <chips 
                [items]="entities" 
                [selectedItems]="selectedAttr.value.input"
                [label]="field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name"
                (updatedItems)="setValue($event, field)"
                [addEnabled]="false"
                [isEditable]="false"
                [isDeleteable]="false"
                (filterItem)="filterEntity(entityModel, $event)"
                (emitScroll)="scrolledDown(entityModel)"
            >
            </chips>
            </div>
            <ng-container *ngFor="let errorField of errorFields">
                <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                    <strong>{{ errorField?.error }}</strong>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'datetime'">
        <ng-container *ngIf="field?.field?.input_type ==='single'">
            <mat-form-field (click)="picker.open()" class="w-100" [style.px]="getHeight(height)">
                <input matInput readonly [matDatepicker]="picker" placeholder="Start" [disabled]="false" formControlName="value"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <ng-container *ngFor="let errorField of errorFields">
                <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                    <strong>{{ errorField?.error }}</strong>
                </mat-error>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type ==='range'">
            <div class="start_date">
                <mat-form-field (click)="startPicker.open()" class="w-100" [style.px]="getHeight(height)">
                    <input matInput readonly [matDatepicker]="startPicker" placeholder="Start" [max]="maxDate" (dateChange)="minValueChanged($event)" [disabled]="false" formControlName="min"/>
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="end_date">
                <mat-form-field (click)="endPicker.open()" class="w-100" [style.px]="getHeight(height)">
                    <input matInput readonly [matDatepicker]="endPicker" placeholder="End" [min]="minDate" (dateChange)="maxValueChanged($event)" [disabled]="false" formControlName="max"/>
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <ng-container *ngFor="let errorField of errorFields">
                <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                    <strong>{{ errorField?.error }}</strong>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'boolean'">
        <ng-container *ngIf="field?.[screenSize]?.component == null">
          <mat-form-field class="w-100" [style.px]="getHeight(height)">
            <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
            <mat-select formControlName="value" (selectionChange)="setValue(+$event.value, field)">
              <mat-option [value]="0">
                No
              </mat-option>
              <mat-option [value]="1">
                Yes
              </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *ngFor="let errorField of errorFields">
            <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                <strong>{{ errorField?.error }}</strong>
            </mat-error>
        </ng-container>
        </ng-container>
      
            <ng-container *ngIf="field?.[screenSize]?.component">
              <ng-container *ngIf="field?.[screenSize]?.component === 'combo'">
                <mat-form-field class="w-100" [style.px]="getHeight(height)">
                  <mat-label>{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                  <mat-select formControlName="value" (selectionChange)="setValue($event.value, field)">
                    <mat-option [value]="'0'">
                      No
                    </mat-option>
                    <mat-option [value]="'1'">
                      Yes
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngFor="let errorField of errorFields">
                  <mat-error *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                      <strong>{{ errorField?.error }}</strong>
                  </mat-error>
              </ng-container>
              </ng-container>
              <ng-container *ngIf="field?.[screenSize]?.component === 'radio'">
                <div class="choices_container" [style.px]="getHeight(height)">
                  <label class="col-form-label float">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</label>
                  <div class="custom_toggle d-flex">
                    <div class="choice_container d-flex">
                      <div class="choice">
                        <input type="radio" (change)="setValue(0, field)" name="value" formControlName="value" [value]="0">
                        <span class="circ"></span>
                      </div>
                      <span>No</span>
                    </div>
                    <div class="choice_container d-flex">
                      <div class="choice">
                        <input type="radio" (change)="setValue(1, field)" name="value" formControlName="value" [value]="1">
                        <span class="circ"></span>
                      </div>
                      <span>Yes</span>
                    </div>
                  </div>
                  <ng-container *ngFor="let errorField of errorFields">
                    <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                        <strong>{{ errorField?.error }}</strong>
                    </div>
                </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="field?.[screenSize]?.component === 'checkbox'">
                <div class="choices_container" [style.px]="getHeight(height)">
                    <div class="choice_container d-flex">
                        <div class="choice" style="margin-top: 30px;">
                            <mat-checkbox formControlName="value" (change)="checkBooleanValue($event, field)">
                            <div style="position: relative; font-weight: normal">{{ field?.field?.name }}</div>
                            </mat-checkbox>
                        </div>
                    </div>
                    <ng-container *ngFor="let errorField of errorFields">
                        <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                            <strong>{{ errorField?.error }}</strong>
                        </div>
                    </ng-container>
                </div>
              </ng-container>
            </ng-container>
          
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'coords'">
        <div class="form-group w-100 m-0" *ngIf="field?.[screenSize]?.component === 'combo' || !field?.[screenSize]?.component">
            <mat-form-field class="w-100" (click)="updateMap()">
                <mat-label class="col-form-label">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                <input matInput formControlName="value">
            </mat-form-field>
        </div>
        <div class="map_container" *ngIf="field?.[screenSize]?.component === 'map'">
            <fap-attributes-map 
                [coords]="coords"
                [mapType]="mapType"
                [isEditMode]="true"
                [height]="height"
                (emitCoords)="updateCoords($event, field)"
            >
            </fap-attributes-map>
        </div>
        <ng-container *ngFor="let errorField of errorFields">
            <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                <strong>{{ errorField?.error }}</strong>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'surface'">
        <div class="form-group w-100 m-0" *ngIf="field?.[screenSize]?.component === 'combo' || !field?.[screenSize]?.component">
            <mat-form-field class="w-100" (click)="updateMap()">
                <mat-label class="col-form-label">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                <input matInput formControlName="value">
            </mat-form-field>
        </div>
        <div class="map_container" *ngIf="field?.[screenSize]?.component === 'map'">
            <fap-attributes-map 
                [polygons]="polygons"
                [mapType]="mapType"
                [isEditMode]="true"
                [height]="height"
                (emitPolygons)="updatePolygons($event, field)"
            >
            </fap-attributes-map>
        </div>
        <ng-container *ngFor="let errorField of errorFields">
            <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                <strong>{{ errorField?.error }}</strong>
            </div>
        </ng-container>
        <!-- <button (click)="updateMap()" class="btn mb-3">{{ 'attribute.editPolygons' | translate }}</button> -->
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'polyline'">
        <div class="form-group w-100 m-0" *ngIf="field?.[screenSize]?.component === 'combo' || !field?.[screenSize]?.component">
            <mat-form-field class="w-100" (click)="updateMap()">
                <mat-label class="col-form-label">{{ field?.field?.name_t ? getTranslation(field?.field?.name_t) : field.name }}</mat-label>
                <input matInput formControlName="value">
            </mat-form-field>
        </div>
        <div class="map_container" *ngIf="field?.[screenSize]?.component === 'map'">
            <fap-attributes-map 
                [polylines]="polylines"
                [mapType]="mapType"
                [isEditMode]="true"
                [height]="height"
                (emitPolylines)="updatePolylines($event, field)"
            >
            </fap-attributes-map>
        </div>
        <ng-container *ngFor="let errorField of errorFields">
            <div class="custom-error" *ngIf="errorField?.uniqueid === field?.field?.uniqueid">
                <strong>{{ errorField?.error }}</strong>
            </div>
        </ng-container>
        <!-- <button (click)="updateMap()" class="btn mb-3">{{ 'attribute.editPolyline' | translate }}</button> -->
    </ng-container>

</form>

    <fap-modal [inputIsMediumLarge]="true"
        #locationPopup
        [titleColor]="'#ffffff'"
        inputTitle="Select Location"
        (outputOnHide)="mapType = ''"
        [showFooter]="false"
        >

        <fap-attributes-map 
        [coords]="coords"
        [polylines]="polylines"
        [polygons]="polygons"
        [mapType]="mapType"
        [isEditMode]="isEditMode"
        [showActions]="true"
        (emitCoords)="updateCoords($event, field)"
        (emitPolylines)="updatePolylines($event, field)"
        (emitPolygons)="updatePolygons($event, field)"
        (closePopup)="closePopup()"
    >
    </fap-attributes-map>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(null)" 
        [prefix]="'FIELD_TYPE__'" 
        [translation]="translation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)"
    >
    </add-edit-translations>
</fap-modal>