import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { LoginBodyInterface } from './data/login-body.interface';
import { RegisterInterface } from './data/register.interface';
import { ConfirmAccountInterface } from './data/confirm-account.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { GlobalRegistryService } from '../../../global-registry/global-registry.service';
import { ResponseModel } from '../../../models/response.model';
import { TokenModel } from '../../../models/auth/token.model';
import { ConfirmPinInterface } from './data/confirm-pin.interface';
import { ConfirmPhoneInterface } from './data/confirm-phone.interface';
import { take } from 'rxjs/operators';


@Injectable()
export class AuthService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient,
                private globalRegistry: GlobalRegistryService) {
        super(tosterService, _http, '/auth/');
    }

    getCounter(tick) {
        return timer(0, tick) 
      }

    public login(body: LoginBodyInterface): Observable<ResponseModel<TokenModel>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/login/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions),
            TokenModel
        );
    }

    public register(body: RegisterInterface): Observable<any> {

        return this.mapRequest(
            this._http.post(this.url + 'user/register/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public resetPassword(body: RegisterInterface):  Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'user/resetpwd/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public confirmAccount(payload: ConfirmAccountInterface): Observable<ResponseModel<TokenModel>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/confirm_password/?mode=1',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.basicRequestOptions
            ), TokenModel
        );
    }

    public confirmPhone(payload: ConfirmPhoneInterface): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/check_phone/',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public changePhone(payload): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/auth_change_phone/',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public changeEmail(payload): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/auth_change_email/',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public confirmPin(payload: ConfirmPinInterface): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/confirm_pin/',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public confirmPhoneEmailPin(payload): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/auth_confirm_email_phone/',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public confirmPassword(payload: ConfirmAccountInterface): Observable<ResponseModel<TokenModel>> {
        return this.mapRequest(
            this._http.post(this.url + 'user/confirm_password/',
                this.camelCaseModelToSnakeCaseJson(payload),
                FapAPIRequestOptions.basicRequestOptions
            ), TokenModel
        );
    }

    public keepAlive(): Observable<ResponseModel<any>> {

        return this.mapRequest(
            this._http.post(this.url + 'user/keep_alive/',
                null,
                {headers: this.headers}
            )
        );
    }

    public logout(): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'user/logout/', null,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public changePassword(body):  Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'user/change_password/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public confirmNewPassword(body):  Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'user/confirm_password/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public removeUser(body):  Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'user/remove/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

    public confirmRemoveUser(body):  Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'user/confirm_remove/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.basicRequestOptions
            )
        );
    }

}
