import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { FarmModel } from 'src/app/core/models/farm/farm.model';
import { LotModel } from 'src/app/core/models/lot/lot.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { DeviceModel, ObjectModel, ObjectService } from 'src/app/core/services/api/objects/object.service';
import { StockService } from 'src/app/core/services/api/stock/stock.service';
import { UnitTypeModel, UnitTypeService } from 'src/app/core/services/api/unit-type/unit-type.service'
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';
import { FapModalComponent } from 'src/app/shared/partials';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { NavService } from '../../../shared/services/nav.service';
import { ScrollService } from '../../../shared/services/scroll.service';
import { filter } from 'rxjs/operators';
import { TypesService } from '../../../core/services/api/types/types.service';
import { FapModalButtonInterface } from '../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { UserService } from '../../../core/services/api/user/user.service';
import { UserModel } from '../../../core/models/user/user.model';
import { UserInterface } from '../../../core/services/api/user/data/user.interface';
import { EntityService } from '../../../core/services/api/entity/entity.service';
import { MapPointInterface } from '../../../shared/layout/fap_main-map/data/map-point.interface';
import { MapPolygonInterface } from '../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { LatLngBounds, LatLngLiteral } from '@agm/core';
import { MapMarkerInterface } from '../../../shared/layout/fap_main-map/data/map-marker.interface';
export interface dataMapInterface {
  sensor: string; object_id: number; unit_type: number; value: number;
}
@Component({
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit, OnDestroy, AfterViewChecked {


  @Input()
  get devices(): Array<DeviceModel> {
    return this._devices;
  }

  set devices(value) {
    this._devices = value;
    // do something
  }

  private _devices: DeviceModel[];
  public dynamicTable: Array<any> = [];
  public tableValues = [];
  public dataMap: Array<any> = [];
  public i = 1;
  private subscriptions: Array<Subscription> = [];
  public filterFarmIds: Array<number> = [];
  public filterLotIds: Array<number> = [];

  public filteredFarms: Array<FarmModel> = [];
  public filteredLots: Array<LotModel> = [];

  public objects: Array<ObjectModel> = [];
  public unitTypes: Array<UnitTypeModel> = [];

  public deviceForm: UntypedFormGroup;

  public tableHeaderLabels = [{index: 1, name: 'type <span>location</span>'}];
  public lotNames = [];
  public resourceEntries = [];
  public entry: any = null;
  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings = {};
  public max: number;
  public profiles = [];
  public mediaUrl = environment.mediaUrl;
  public deviceData = [];
  public isLoading = false;
  public searchString = '';
  @ViewChild('configModal') configModal: FapModalComponent;
  @ViewChild('deviceModal') deviceModal: FapModalComponent;
  @Output()
  public addNewDevice: EventEmitter<any> = new EventEmitter<any>();
  public nextToken: { group: string, limit: number; offset: number; search: string; } = null;
  public deviceNextToken: { limit: number; offset: number; search: string; lot: string, group: string } = null;
  public getMore = true
  public getMoreData = true
  public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  public searchLots = [];
  public sensorGroup = [];
  public itemList = [];
  public virtualItemList = [];
  public sensorForm: UntypedFormGroup;
  public sensorGroupTypeForm: UntypedFormGroup;
  public sensors: SensorModel[] = [];
  public translatedNames:any = [];
  public langString: string;
  public deviceColumns = [];
  public allDeviceColumns = [];
  public sensorGroupTypes = [];
  public draggedItemIndex: number;
  private scrollPosition = 0;
  public divHeight:number;
  @ViewChild('deviceContainer') deviceContainer: ElementRef;
  private isScrolled = false;
  private previousQueryParams: any = {};
  public filteredColumns: any[] = [];
  public translation = null;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  public sensorGroupType: any;
  public addEditSensorGroupTypeModalButtonPrimaryInterface: FapModalButtonInterface;
  public addEditSensorGroupTypeModalButtonSecondaryInterface: FapModalButtonInterface;
  public sensorGroupTypeId: any = null;
  @ViewChild('addEditSensorGroupTypeModal')
  public addEditSensorGroupTypeModal: FapModalComponent;
  @ViewChild('mys') mys;
  public user: UserModel;
  public label:string;
  public closeCombo: boolean = true;
  public isSelectDialogOpen: boolean = false;
  public columnWidths: number[] = []
  public entityTypes: any[] = [];
  public entityTypesMap: Map<String, any> = new Map();
  public formFieldTypes: any[] = [];
  public formFieldTypesMap: Map<string, any> = new Map();
  public numberArray: number[];

  constructor(public mapService: MapService, public unitTypeService: UnitTypeService, public activatedRoute: ActivatedRoute, public globalRegistry: GlobalRegistryService, public stockService: StockService, public formBuilder: UntypedFormBuilder, public toastrService: ToastrService, public translateService: TranslateService, public objectService: ObjectService, public cacheService: CacheResolverService, public router: Router, public widgetsService: WidgetsService, private sanitizer: DomSanitizer, public sensorService: SensorService, public navService: NavService, public fb: UntypedFormBuilder, private scrollService: ScrollService, public typeService: TypesService, public toastr: ToastrService, private userService: UserService, private entityService: EntityService) { 
    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    this.columnWidths = this.deviceColumns.map(() => null);
  // this.activatedRoute.queryParams.subscribe(queryParams => {
  //   if (Object.keys(queryParams).length === 0) {
  //     this.searchString = '';
  //     this.getDeviceData();
  //   }
  // })

  this.initSensorGroupTypeForm();

  this.router.events
  .pipe(filter(event => event instanceof NavigationEnd))
  .subscribe((event: NavigationEnd) => {
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    console.log(event)
    if (this.previousQueryParams['filter'] !== undefined && Object.keys(currentQueryParams).length === 0) {
      this.searchString = '';
    }
0
    this.previousQueryParams = { ...currentQueryParams };
  });


  this.getScrollPosition();
  }

  initSensorGroupTypeForm() {
    this.sensorGroupTypeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      name_t: new UntypedFormControl(''),
      color: new UntypedFormControl('#ff0000'),
    });
  }

  getEntityTypes() {
    this.typeService.getEntityTypes().subscribe(data => {
      this.entityTypes = data.body.results;
      this.entityTypesMap = new Map(this.entityTypes.map(type => [type.id, type]));
    })
  } 

  getScrollPosition() {
    this.scrollPosition = this.scrollService.getScrollPosition('device');
      setTimeout(() => {
        if(this.deviceData.length) {
          if (this.deviceContainer) {
            this.divHeight = this.deviceContainer.nativeElement.clientHeight;
            const contentHeight = this.deviceContainer.nativeElement.scrollHeight;
            if(this.scrollPosition > contentHeight) {
              this.scrolledDownDeviceData();
            }
            this.deviceContainer.nativeElement.scrollTop = this.scrollPosition;
          }
        }
      }, 500);
  }

  getFieldTypes() {
    this.typeService.getFormFieldTypes().subscribe(data => {
      this.formFieldTypes = data.body.results;
      this.formFieldTypesMap = new Map(this.formFieldTypes.map(type => [type.field.id, type.field]));
    });
  }

  ngOnInit(): void {
    this.userService.getCurrentUser.subscribe(data => {
      this.user = data;
        this.sensorGroupTypeId = (this.user.profile.data.devices_view && this.user.profile.data.devices_view.selected_column) ? this.user.profile.data.devices_view.selected_column : '';
        // this.getDeviceData(this.sensorGroupTypeId);
    })
    console.log(this.sensorGroupTypeId);
    this.mapService.showMap();
    this.widgetsService.setSize(6);
    this.mapService.resetMap();
    this.mapService.isEditMode = false;
    this.initDeviceForm();
    this.getProfiles();
    this.getDeviceData(this.sensorGroupTypeId);
    this.getAllDeviceDataColumns();
    this.getDeviceDataColumns(this.sensorGroupTypeId);
    this.getSensorGroupTypes();
    this.getFieldTypes();
    this.getEntityTypes();
    this.sensorForm = this.fb.group({
      sensorIds: [[], Validators.required],
      sensorGroup: [this.sensorGroupTypeId, Validators.required]
    });
    this.initAddsensorGroupTypeModalButtons();
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
      this.searchLots = data?.lots ?? [];
      if(this.searchLots.length) {
        this.getDeviceData(this.sensorGroupTypeId)
      }
      if (data["filter"] !== undefined) {
        const search = (data["filter"] as string).toLowerCase();
        this.searchString = search;
        this.getDeviceData(this.sensorGroupTypeId)
      }
    }))
    this.langString = localStorage.getItem('language');
      this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
        this.langString = lang;
      }
    }));
    this.translatedNames = this.globalRegistry.systemData.translations;
    
  }

  get f() {
    return this.deviceForm.controls;
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrolledDown();
    }
}

onDivScroll(event: any) {
  const divElement = event.target as HTMLElement;
    const scrollTop = divElement.scrollTop;
    this.scrollPosition = scrollTop;
    this.scrollService.setScrollPosition(scrollTop, 'device')
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    this.scrolledDownDeviceData();
  }
}

scrolledDown() {
  const url = this.stockService.getUrl('stock_resources/');
  console.log('scrolled');
  if(this.getMore) {
  this.nextToken &&
    this.subscriptions.push(this.stockService.getDevicesResourceEntries(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'group=DEVICES&limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
      if(this.resourceEntries) {
        this.resourceEntries = [...this.resourceEntries, ...data.body.results];
      } else {
        this.resourceEntries = data.body.results;
      }
      if(data.body.next == null) {
        this.getMore = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        if(this.nextToken.offset != params['offset']) {
        this.nextToken = {group: 'DEVICES', limit: params['limit'], offset: params['offset'], search: params['search']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
}

scrolledDownDeviceData() {
  const url = this.entityService.getUrl('adv_data/');
  console.log('scrolled');
  if(this.getMoreData) {
  this.deviceNextToken &&
    this.subscriptions.push(this.entityService.getEntities(this.deviceNextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.deviceNextToken.limit+'&offset='+this.deviceNextToken.offset+'&search='+this.deviceNextToken.search);
      if(this.deviceData) {
        const uniqueIds = new Set(this.deviceData.map(item => item.id));
        const newData = data.body.results.filter(item => !uniqueIds.has(item.id));
        this.deviceData = [...this.deviceData, ...newData];
        this.getScrollPosition();
      } else {
        this.deviceData = data.body.results;
        this.getScrollPosition();
      }
      if(data.body.next == null) {
        this.getMoreData = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        if(this.deviceNextToken.offset != params['offset']) {
        this.deviceNextToken = {limit: params['limit'], offset: params['offset'], search: params['search'] ? params['search'] : '', lot: params['lot'] ? params['lot'] : '', group: params['group']};
        } else {
          return
        }
      }
    }));
  } else {
    return
  }
}

paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

  public getResourceEntries() { 
    const url = this.stockService.getUrl('stock_resources/')
    this.cacheService.delete(url+'group=DEVICES');
    const param = {group:'DEVICES'}
    this.stockService.getDevicesResourceEntries(param).subscribe(data => {
      this.resourceEntries = data.body.results;
      if(data.body.results) {
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            const search = {search : ''}
            Object.assign(this.nextToken, search)
      if(this.nextToken) this.getMore = true;
      }
    })
  }

  public setEntry(entry) {
    const entries = this.resourceEntries.filter(e => {
      return e.id == entry.value;
    });
    this.entry = entries[0];
    if(entry.value != null) {
      this.max = this.entry.quantity;
      this.deviceForm.controls['quantity'].setValue(this.entry.remains_quantity);
    }
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
    }
  }

  onOptionSelected(event) {
      const ref = { field_type: event.value };
  
      if (!this.isDuplicateItem(ref, this.itemList)) {
        this.itemList.push(ref);
      }
  
      const selectedColumn = this.allDeviceColumns.find(column => column?.field_type?.id === ref.field_type);
  
      if (selectedColumn && !this.isDuplicateColumn(selectedColumn, this.deviceColumns)) {
        this.deviceColumns.push(selectedColumn);
      }
  }
  
  isDuplicateItem(newItem, itemList) {
    return itemList.some(existingItem =>
      existingItem.field_type === newItem.field_type
    );
  }
  
  isDuplicateColumn(newColumn, deviceColumns) {
    return deviceColumns.some(existingColumn =>
      existingColumn?.field_type?.id === newColumn?.field_type?.id
    );
  }  

addColumns() {
  console.log(this.sensorForm.value);
  this.sensorGroupTypeId = this.sensorForm.get('sensorGroup').value;
  this.entityService.postColumns(this.itemList, this.sensorGroupTypeId ? this.sensorGroupTypeId : '').subscribe(data => {
    if(data) {
      this.toastrService.success('Header updated');
      console.log(data);
      this.configModal.hideModal();
      this.deviceColumns = data.body.results;
      localStorage.setItem('currentSensorGroup', this.sensorGroupTypeId);
      this.getDeviceData(this.sensorGroupTypeId);
    }
  })
}

showConfigModal() {
  this.configModal.showModal();
}

  showModal() {
    this.deviceModal.showModal();
}

  hideModal() {
    const elem = document.getElementById('device_modal');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.router.navigate(['pages/devices'])
    this.deviceForm.reset();
    this.deviceForm.clearValidators();
    this.initDeviceForm();
    this.entry = null;
  }

  public addDevice() {
    if(this.deviceForm.invalid) {
      this.deviceForm.markAsTouched();
      this.toastrService.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
      return
    }
    this.toastrService.success('New device created successfully');
    this.addNewDevice.emit(this.deviceForm.value);
    this.hideModal();
  }

  updateItems(lots) {
    this.deviceForm.get('lots').setValue(lots);
  }

  public initDeviceForm() {
    const formBody = {
      resource_entry : ['', Validators.required],
      quantity: [0, Validators.required],
      lots: [[], Validators.required]
    };
    this.deviceForm = this.formBuilder.group(formBody);
  }

  public removeItem(field_type_id: number): void {
    // const sensorIdsArray = this.sensorForm.get('sensorIds').value;
    // const index = sensorIdsArray.indexOf(field_type_id);
  
    // if (index !== -1) {
    //   this.sensorForm.get('sensorIds').setValue(sensorIdsArray.filter(id => id !== field_type_id));
    // }
  
    this.itemList = this.itemList.filter(item => item.field_type !== field_type_id);
  
    this.deviceColumns = this.deviceColumns.filter(column => column?.field_type?.id !== field_type_id);
  }
  
  
  
  toDateTime(secs) {
    return moment.unix(secs).utc().local().fromNow();
}

  mergeArrays(arrays) {
    return [...new Set([].concat(...arrays))];
  }

  getUnitTypeText(unitType) {
    const unitTypeObj = this.unitTypes.find(u => u.id === unitType);
    return unitTypeObj ? unitTypeObj.unit : '';
  }
 
  
  getCellStyles(sensor) {
    if(sensor) {
      let val = null;
      let valuePresent = false;
      val = sensor['representation']
      valuePresent = sensor['value_float'] || sensor['value_string']
      if(val && val.table && val.table.profile) {
      return this.getProfile(val.table.profile, valuePresent)
      }
    }
  }

  getProfile(profile, valuePresent) {
    const matchedProfile = this.profiles.find(obj => obj.uniqueid === profile);
      if(matchedProfile) {
        let img = ''
        if(matchedProfile.image) {
          img = this.mediaUrl + matchedProfile.image.file;
        }
        return {
          'font-weight': matchedProfile.bold ? 'bold' : 'normal',
          'font-style': matchedProfile.italic ? 'italic' : 'normal',
          'font-size': matchedProfile.text_size,
          'color': matchedProfile.text_color,
          'background-color': matchedProfile.back_color,
          'background-image': '',
          'background-position': valuePresent ? '5px center' : 'center',
          'img': img,
          'translate_x': matchedProfile.translate_x,
          'translate_y': matchedProfile.translate_y,
          'show_icon': matchedProfile.show_icon,
          'show_text': matchedProfile.show_text,
          'show_value': matchedProfile.show_value
        };
      }
  }

  getExtraFieldsById(field, unitId) {
    if (!field) {
      return '-';
    }
  
    // Initialize variables
    let unit = null;
    let type = null;
    let unitShortName = '';
    let valueType = null;
    let inputType = null;
    let value = '-';
  
    // Get unit information if unitId is provided
    if (unitId) {
      unit = this.globalRegistry.systemData.unitsMap.get(unitId);
      unitShortName = unit ? unit.shortName : '';
    }
  
    // Get field type and its properties
    if (field.type) {
      type = this.formFieldTypesMap.get(field.type);
      if (type) {
        valueType = type.value_type;
        inputType = type.input_type;
      }
    }
  
    // Handle different value types
    switch (valueType) {
      case 'string':
        value = this.handleStringType(field, inputType);
        break;
      case 'number':
        value = this.handleNumberType(field, inputType, unitShortName);
        break;
      case 'datetime':
        value = this.handleDateTimeType(field, inputType);
        break;
      case 'entity':
        value = this.handleEntityType(field, inputType);
        break;
      case 'boolean':
        value = this.handleBooleanType(field);
        break;
      case 'image':
        value = this.handleImageType(field, inputType);
        break;
      case 'coords':
        value = this.handleCoordsType(field);
        break;
      case 'surface':
        value = this.handleSurfaceType(field);
        break;
      case 'polyline':
        value = this.handlePolylineType(field);
        break;
      default:
        value = '-';
    }
  
    return value;
  }
  
  // Handle string type fields
  handleStringType(field, inputType) {
    if (inputType === 'single' || inputType === 'choice') {
      const valueMap = field.value ? this.globalRegistry.systemData.translationsMap.get(field.value.input) : '-';
      const value = valueMap ? valueMap[this.langString] : '-';
      return value.trim() === '' ? '-' : value;
    } else if (inputType === 'multiselect') {
      return field.value && field.value.input.length > 0 ? field.value.input.toString() : '-';
    }
    return '-';
  }
  
  // Handle number type fields
  handleNumberType(field, inputType, unitShortName) {
    const unitDiv = `<span class="unit">${unitShortName}</span>`;
    if (inputType === 'single' || inputType === 'choice') {
      return field.value ? `${field.value.input}${unitDiv}` : '-';
    } else if (inputType === 'multiselect') {
      return field.value && field.value.input.length > 0 ? field.value.input.toString() : '-';
    } else if (inputType === 'range') {
      const { min, max } = field.value.input;
      if (min && max) {
        return `<div class="d-flex"><div class="min_value">${min}${unitDiv}</div><div class="divider">-</div><div class="max_value">${max}${unitDiv}</div></div>`;
      }
    }
    return '-';
  }
  
  // Handle datetime type fields
  handleDateTimeType(field, inputType) {
    if (inputType === 'single' || inputType === 'choice') {
      return field.value ? field.value.input : '-';
    } else if (inputType === 'range') {
      const { min, max } = field.value.input;
      if (min && max) {
        return `<div class="d-flex"><div class="min_value">Min: ${min}</div><div class="max_value">Max: ${max}</div></div>`;
      }
    }
    return '-';
  }
  
  // Handle entity type fields
  handleEntityType(field, inputType) {
    if (inputType === 'single' || inputType === 'choice') {
      return field.value.input;
    } else if (inputType === 'multiselect') {
      return field.value.input.toString();
    }
    return '-';
  }
  
  // Handle boolean type fields
  handleBooleanType(field) {
    return field.value.input === 0 ? 'False' : 'True';
  }
  
  // Handle image type fields
  handleImageType(field, inputType) {
    if (inputType === 'single' || inputType === 'choice') {
      return field.value.input;
    } else if (inputType === 'multiselect') {
      return field.value.input.toString();
    }
    return '-';
  }
  
  // Handle coords type fields
  handleCoordsType(field) {
    if(field.value.input && field.value.input.type)
    this.loadMapMarker(field.value.input);
    return '<span class="btn custom_btn_img"><img src="../../assets/icons/marker-white.png" alt="marker"></span>';
  }
  
  // Handle surface type fields
  handleSurfaceType(field) {
    if (field.value.input && field.value.input[0].points.length) {
      this.loadPolygonPath(field.value.input);
      return '<span class="btn custom_btn_img no_bg"><img src="../../assets/icons/polygon.png" alt="marker"></span>';
    }
    return '-';
  }
  
  // Handle polyline type fields
  handlePolylineType(field) {
    if (field.value.input && field.value.input[0].points.length) {
      this.loadPolyPath(field.value.input);
      return '<span class="btn custom_btn_img no_bg"><img src="../../assets/icons/polyline.png" alt="marker"></span>';
    }
    return '-';
  }
  
  public loadMapMarker(marker): void {
    const coords = marker.coordinates;
    if (coords) {
        const lat = parseFloat(coords[0]);
        const lng = parseFloat(coords[1]);
        const newMarker: MapMarkerInterface = {
            lat,
            lng
        };
        const markerExists = this.mapService.mapMarkers.some(existingMarker =>
            existingMarker.lat === newMarker.lat && existingMarker.lng === newMarker.lng
        );
        if (!markerExists) {
            this.mapService.mapMarkers.push(newMarker);
            console.log(this.mapService.mapMarkers);
        }
    }
  }
  
  public loadPolyPath(markers) {
    if (!markers) {
      return;
    }
    const points = markers[0].points.map(element => ({ lat: element.lat, lng: element.lng }));
    const polylineExists = this.mapService.mapPolylines.some(polyline => 
      polyline.points.length === points.length && 
      polyline.points.every((point, index) => point.lat === points[index].lat && point.lng === points[index].lng)
    );
    if (polylineExists) {
      return;
    }
    this.mapService.mapPolylines.push({ points: points });
  }
  
  public loadPolygonPath(polygon) {
    if(!polygon || !polygon.points) {
      return;
    }
    const newPolygon: MapPolygonInterface = {
      points: polygon.points,
      strokeColor: polygon.strokeColor,
      fillColor: polygon.fillColor,
      strokeWeight: 5,
      isEditable: false,
    };
  
    const polygonExists = this.mapService.mapPolygons.some(existingPolygon => 
      existingPolygon.points.length === newPolygon.points.length &&
      existingPolygon.points.every((point, index) => 
        point.lat === newPolygon.points[index].lat && point.lng === newPolygon.points[index].lng
      )
    );
  
    if (polygonExists) {
      return;
    }
    this.mapService.mapPolygons.push(newPolygon);
  }
  
  
  findEntityValue(entity, input) {
    console.log(entity, input);
  }

selectAllItems() {
  this.deviceColumns = this.allDeviceColumns;
  this.deviceColumns.forEach(column => {
    this.itemList.push({field_type: column.field_type.id});
  });
}

removeAllItems() {
  this.deviceColumns = [];
  this.itemList = [];
  // this.sensorForm.reset();
}

handleButtonClick(field) {
  if (field.value.input && field.value.input.type === 'Point') {
    this.mapService.currentLocation.lat = field.value.input.coordinates[0];
    this.mapService.currentLocation.lng = field.value.input.coordinates[1];
    this.mapService.mainMapIsOpened = true;
    this.mapService.mapZoom.next(20);
    this.mapService.mapFocusPoint.lat = field.value.input.coordinates[0];
    this.mapService.mapFocusPoint.lng = field.value.input.coordinates[1 ];
  } else if (field.value.input && field.value.input[0].fillColor) {
    this.loadPolygonPath(field.value.input[0]);
    this.mapService.centerMapOnPolygon(field.value.input[0]);
  } else if (field.value.input && field.value.input[0].points) {
    this.loadPolyPath(field.value.input);
    this.mapService.centerMapOnPolyline(field.value.input[0]);
  }
}

  displayAllCoords() {
    const uniqueMarkers = new Set<string>();
  
    this.deviceData.forEach(device => {
      if (device.coords && device.coords.last_value) {
        const markerKey = JSON.stringify(device); 
  
        if (!uniqueMarkers.has(markerKey)) {
          uniqueMarkers.add(markerKey);
  
          const infoWindowDetails = [];
          if (device.coords.last_value.representation) {
            const profile = this.getProfile(device.coords.last_value.representation.map.profile, device.coords.last_value.value_coord);
            let iconUrl;
            let label;
            const infoWindowDetails = [];
            if (profile && profile.show_icon) {
              iconUrl = {
                url: profile['img'],
                scaledSize: { width: 30, height: 30 }
              };
            }
            if (device.coords.last_value.representation.map.text && profile && profile.show_text) {
              label = { className: 'custom-white-marker-label', 'text': device.coords.last_value.representation.map.text, 'color': profile.color, 'font-size': '14px', 'background-color': 'red' };
            }
           
              infoWindowDetails.push({ img: null, text: device.coords.last_value.value_float ? device.coords.last_value.value_float.toFixed(2) : device.id, link: 'devices/view/'+device.id});
           
            if (device.coords && device.coords.last_value && profile && device.coords.last_value.value_coord) {
              const markerInterface = {
                lat: device.coords.last_value.value_coord.coordinates[1] + profile.translate_y,
                lng: device.coords.last_value.value_coord.coordinates[0] + profile.translate_x,
                // zoom: device.coords.last_value.value_coord.coordinates[2],
                label: label,
                iconUrl: iconUrl ? iconUrl : 'https://via.placeholder.com/15x15.png/000000?text=+',
                infoWindowDetails: infoWindowDetails
              };
              this.mapService.mapMarkers.push(markerInterface);
            }
          } else {
            infoWindowDetails.push({ img: null, text: device.id });
            const markerInterface = {
              lat: device.coords.last_value.value_coord.coordinates[1],
              lng: device.coords.last_value.value_coord.coordinates[0],
              // zoom: device.coords.last_value.value_coord.coordinates[2],
              infoWindowDetails: infoWindowDetails ? infoWindowDetails : null,
              mapIconUrl: ''
            };
            this.mapService.mapMarkers.push(markerInterface);
          }
        }
      }
    });
  
    if (this.mapService.mapMarkers.length) {
      console.log(this.mapService.mapMarkers);
      this.mapService.centerMapOnMarkers();
    }

  }
  
  displayCoord(sensor, device) {
    let iconUrl;
    let label;
    let profile
    const infoWindowDetails = [];
    if (sensor && sensor.representation) {
      profile = this.getProfile(sensor.representation.map.profile, sensor.value_coord);
  
      if (profile && profile.show_icon) {
        iconUrl = {
          url: profile['img'],
          scaledSize: {
            width: 30,
            height: 30
          }
        };
      }
  
      if (sensor.representation.map.text && profile && profile.show_text) {
        label = {
          className: 'custom-white-marker-label',
          'text': sensor.representation.map.text,
          'color': profile.color,
          'font-size': '14px',
          'background-color': 'red'
        };
      }
    }
  
      infoWindowDetails.push({ img: null, text: sensor.value_float ? sensor.value_float.toFixed(2) : device, link: 'devices/view/'+device});
  
      if (sensor.value_coord) {
        const newMarker = {
          lat: sensor.value_coord.coordinates[1] + (profile ? profile.translate_y : 0),
          lng: sensor.value_coord.coordinates[0] + (profile ? profile.translate_x : 0),
          // zoom: sensor.value_coord.coordinates[2],
          label: label,
          iconUrl: iconUrl ? iconUrl : '',
          infoWindowDetails: infoWindowDetails
        };
  
        // Check for duplicates before pushing
        if (!this.isDuplicateMarker(newMarker)) {
          this.mapService.mapMarkers.push(newMarker);
  
          if (this.mapService.mapMarkers.length) {
            this.mapService.centerMapOnMarkers();
          }
        }
      }
    
  }
  
  // Helper function to check for duplicates
  isDuplicateMarker(newMarker) {
    return this.mapService.mapMarkers.some(existingMarker =>
      existingMarker.lat === newMarker.lat &&
      existingMarker.lng === newMarker.lng
    );
  }
  

  public addUnitToValue(unitId, value) {
    let unit = ''
    if(value) {
      unit = value.toString();
      this.unitTypes.forEach(u => {
        if(u.id == unitId) {
          unit = unit + '<span>' + (u.unit ? u.unit : '') + '</span>';
        }
      })
    }
    return unit;
  }

  public findName(objectId, unitId) {
    let unit = '';
    let obj = '';
    this.unitTypes.forEach(u => {
      if(u.id == unitId) {
        if(u.name != 'null') {
          unit = u.name
        }
      }
    })
    this.objects.forEach(o => {
      if(o.id == objectId) {
        if(o.extraText != 'null') {
          obj = o.extraText
        }
      }
    })
    return (obj ? obj : '') +' <span>'+ (unit ? unit : '') + '</span>'
  }

  getProfiles() {
    // let url = this.widgetsService.getUrl('ui_profiles/');
    // this.cacheService.delete(url);
    this.widgetsService.getProfiles().subscribe(data => {
      this.profiles = data.results;
    })
  }

  getAllDeviceDataColumns() {
    let url = this.entityService.getUrl('adv_data/columns/')
    this.entityService.getEntitiesColumns({filter: 'all'}).subscribe(data => {
      this.cacheService.delete(url+'filter=all');
      this.allDeviceColumns = data.body.results;
      this.filteredColumns = this.allDeviceColumns.map(column => ({
        ...column,
        highlighted: false
      }));
    })
  }

  getDeviceDataColumns(group = '') {
    group = group ? group: '';
    this.itemList = [];
    const url = this.entityService.getUrl('adv_data/columns/')
    this.entityService.getEntitiesColumns({group: group}).subscribe(data => {
      this.cacheService.delete(url+'group='+group);
      this.deviceColumns = data.body.results;
      this.numberArray = Array.from({ length: data.body.results.length }, (_, i) => i);
      const sensorIds = [];
      this.deviceColumns.forEach(column => {
        this.itemList.push({field_type: column.field_type.id});
        sensorIds.push(column.field_type.id)
      });
      // this.sensorForm.get('sensorIds').setValue(sensorIds);
    })
  }

  getDeviceData(group?:any) {
    this.isLoading = true;
    this.deviceData = [];
    this.mapService.mapMarkers = [];
    const params = {
      limit: 20,
      offset: 0,
      search: '',
      group: group ? group : ''
    }
    const url = this.entityService.getUrl('adv_data/');
    if(this.searchString) { Object.assign(params, {search : this.searchString}) }
    this.entityService.getEntities(params).subscribe(data => {
      this.deviceData = data.body.results;
      this.isLoading = false;
      this.cacheService.delete(url+'limit=20&offset=0&search='+params.search+'&group='+params.group);
      if(this.deviceData) {
        // this.displayAllCoords();
        this.getScrollPosition();
        if(data.body.next == null) {
          this.getMoreData = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          this.deviceNextToken = {limit: params['limit'], offset: params['offset'], search: params['search'] ? params['search'] : '', lot: params['lot'] ? params['lot'] : '', group: params['group']};
        }
      }
    })
  }

  reroute() {
    this.router.navigate(['pages/entities']);
  }

  onDragStart(event: DragEvent, index: number) {
    this.draggedItemIndex = index;
  }
  
  allowDrop(event: DragEvent) {
    event.preventDefault();
  }
  
  onDrop(event: DragEvent) {
    event.preventDefault();
    const targetIndex = this.findIndexFromEvent(event);
  
    if (targetIndex !== -1 && this.draggedItemIndex !== targetIndex) {
      this.moveItem(this.deviceColumns, this.draggedItemIndex, targetIndex);
      this.moveItem(this.itemList, this.draggedItemIndex, targetIndex);
    }
  
    this.draggedItemIndex = undefined;
  }
  
  findIndexFromEvent(event: DragEvent): number {
    const targetElement = event.target as HTMLElement;
    const dropTarget = targetElement.closest('[draggable="true"]');
    return dropTarget ? Array.from(dropTarget.parentElement.children).indexOf(dropTarget) : -1;
  }
  
  moveItem(array: any[], fromIndex: number, toIndex: number) {
    const [removedItem] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, removedItem);
  }

  onFilterChange(value: string) {
    console.log('Filter Value:', value); // Log the filter value
    this.filteredColumns = this.allDeviceColumns.map(column => ({
      ...column,
      highlighted: column.name.toLowerCase().includes(value.toLowerCase())
    }));
    console.log(this.filteredColumns);
  }  

  ngAfterViewChecked() {
    if (!this.isScrolled && this.deviceContainer && this.scrollPosition > 0) {
      // const contentHeight = this.deviceContainer.nativeElement.scrollHeight;
      if (this.scrollPosition > this.divHeight) {
        this.deviceContainer.nativeElement.scrollTop = this.scrollPosition + this.divHeight;
        this.isScrolled = true;
      }
    }
  }

  getSensorGroupTypes() {
    const url = this.typeService.getUrl('sensor_group/');
    this.typeService.getSensorGroupTypes().subscribe(data => {
      this.cacheService.delete(url);
      this.sensorGroupTypes = data.body.results;
    })
  }

  sensorGroupTypeEdit(sensorGroup){
    this.sensorGroupTypeId = sensorGroup.id;
    this.sensorGroupType = sensorGroup;
    console.log(this.sensorGroupType)
    this.sensorGroupTypeForm.controls['name'].setValue(sensorGroup.name);
    this.sensorGroupTypeForm.controls['name_t'].setValue(sensorGroup.nameT);
    this.sensorGroupTypeForm.controls['color'].setValue(sensorGroup.color);
    this.showSensorGroupTypeModal(sensorGroup);
}

sensorGroupTypeDelete(sensorGroupType){
    console.log(sensorGroupType);
    if(sensorGroupType.id == this.sensorGroupTypeId) {
      this.sensorGroupType = null;
      this.sensorGroupTypeId = null;
    }
    this.typeService.deleteSensorGroupType(sensorGroupType.id).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translateService.instant('sensor.deleteSensorGroupType'))
      this.getSensorGroupTypes();
    })
}

  changeGroup(group) {
    this.getDeviceDataColumns(group);
    // this.userService.updateUser(body)
    const body = {
      id: this.user.id,
      profile: {
          data: {
            devices_view: {
              selected_column: group
            },
            timezone: this.user.profile.data.timezone,
            language: this.user.profile.data.language,
            allowed: this.user.profile.data.allowed
          }
      },
    };
    this.userService.updateUser(body).subscribe();
  }

  addEditTranslation(label) {
    this.label = label;
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.sensorGroupType);

    if (name === '' || type.nameT === null) {
        this.translation = null;
        this.addEditTranslationsModal.showModal();
        return;
    }

    for (const translation of this.globalRegistry.systemData.translations) {
        if (type && translation.id === type.nameT) {
            this.translation = translation;
            console.log(this.translation);
            this.addEditTranslationsModal.showModal();
            return;
        }
    }

    if (Object.keys(type).length === 0 && name) {
        const newTranslation = this.sensorGroupType.controls['name_t'].value;
        for (const translation of this.globalRegistry.systemData.translations) {
            if (translation.id === newTranslation) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
                return;
            }
        }
    }
}

  convertKeysToCamelCase(obj) {
      const camelCaseObj = {};
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
      }
      return camelCaseObj;
    }

    public initAddsensorGroupTypeModalButtons(): void {
      const _this: EntitiesComponent = this;

      this.addEditSensorGroupTypeModalButtonPrimaryInterface = {
          clickFunction(): void {
              _this.sensorGroupTypeSubmit();
          },
          text: this.translateService.instant('save')
      };

      this.addEditSensorGroupTypeModalButtonSecondaryInterface = {
          clickFunction(): void {
              _this.addEditSensorGroupTypeModal.hideModal();
          },
          text: this.translateService.instant('cancel')
      };
  }

  public sensorGroupTypeSubmit(){
    if(this.sensorGroupTypeForm.invalid) {
        Object.keys(this.sensorGroupTypeForm.controls).forEach((controlName: string) =>
            this.sensorGroupTypeForm.controls[controlName].markAsTouched()
        );
        return;
    }
    if(this.sensorGroupTypeId){
        this.editSensorGroupType(this.sensorGroupTypeForm.value, this.sensorGroupTypeId);
        this.addEditSensorGroupTypeModal.hideModal();
        
    } else {
        this.addSensorGroupType(this.sensorGroupTypeForm.value)
        this.addEditSensorGroupTypeModal.hideModal();
        
    }
  }

  public addSensorGroupType(sensorGroup): void {
    this.typeService.addSensorGroupType(sensorGroup).subscribe((data) => {
      if(data) {
        this.sensorGroupType = data.body.results;
        this.sensorGroupTypeId = this.sensorGroupType.id;
        this.sensorForm.get('sensorGroup').setValue(this.sensorGroupTypeId);
        this.changeGroup(this.sensorGroupTypeId)
      }
        this.toastr.success(this.translateService.instant('sensor.addSensorGroupType'));
        this.getSensorGroupTypes();
        this.closeCombo = true;
    });    
}

public editSensorGroupType(sensorGroup, sensorGroupTypeId: number): void {
        this.typeService.updateSensorGroupTypes(sensorGroupTypeId, sensorGroup).subscribe((data) => {
        if(data) {
          this.sensorGroupType = data.body.results;
          this.sensorGroupTypeId = this.sensorGroupType.id;
          const index = this.sensorGroupTypes.findIndex(type => type.id === sensorGroupTypeId);
          if (index !== -1) {
            this.sensorGroupTypes[index] = data.body.results;
          }
          this.sensorForm.get('sensorGroup').setValue(this.sensorGroupTypeId);
          this.changeGroup(this.sensorGroupTypeId)
        }
        this.toastr.success(this.translateService.instant('sensor.updateSensorGroupType'));
        this.getSensorGroupTypes();
        this.closeCombo = true;
    });    
}

  public showSensorGroupTypeModal(sensorGroup?:any): void {
      if(sensorGroup) {
          this.sensorGroupType = sensorGroup
          this.sensorGroupTypeId = sensorGroup.id;
      } else {
        this.sensorGroupType = null
        this.sensorGroupTypeId = null;
        this.initSensorGroupTypeForm();
      }
      this.addEditSensorGroupTypeModal.showModal();
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.sensorGroupTypeForm.controls['name_t'].setValue(translation.id);
    this.sensorGroupTypeForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
  }

  onResize(width: number, index: number) {
    this.columnWidths[index] = width;
    console.log(`Column ${index} resized to: ${width}px`);
  }

  public ngOnDestroy() {
    this.mapService.isEditMode = true;
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

}
