import {
    Component,
    AfterViewInit, Injectable, OnInit, OnDestroy
} from '@angular/core';

import {
    trigger,
    transition,
    useAnimation
} from '@angular/animations';

import {
    fadeIn
} from 'ng-animate';
import * as feather from 'feather-icons';

import { Menu, NavService } from '../../services/nav.service';
// import { CustomizerService } from '../../services/customizer.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { FarmModel } from '../../../core/models/farm/farm.model';
import { of as ofObservable, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LotModel } from '../../../core/models/lot/lot.model';
import { CustomizerService } from '../../services/customizer.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { MapService } from '../fap_main-map/service/map-service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { environment } from '../../../../environments/environment';
import { ChangeDetectorRef } from '@angular/core';

export class TodoItemNode {
    public children: TodoItemNode[];
    public item: string;
}

export class TodoItemFlatNode {
    public item: string;
    public level: number;
    public expandable: boolean;
}

@Injectable()
export class CheckListDatabase {
    public plantationFilter: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);
    public farms: {};
    public TREE_DATA: {};
    public size;
    public get data(): TodoItemNode[] {
        return this.dataChange.value;
    }

    constructor(public globalRegistry: GlobalRegistryService, public widgetService:WidgetsService) {
        this.globalRegistry.newFarmOrLotAdded.subscribe(()=>{
            this.getTreeData();
            this.initialize();
        })
    }

    public initialize(): void {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(this.TREE_DATA, 0);
        // Notify the change.
        this.dataChange.next(data);
    }

    public getTreeData(): void {
        const treeFarms = {};
        this.plantationFilter.subscribe((value: string) => {
            this.globalRegistry.systemData.farms.forEach((farm: FarmModel) => {
                if (value) {
                    treeFarms[farm.id] = {};
                    this.globalRegistry.systemData.lots.forEach((lot: LotModel) => {
                        if (lot.name.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                            treeFarms[farm.id][lot.id] = null;
                            this.TREE_DATA = Object.assign({}, treeFarms);
                        } else {
                            this.TREE_DATA = {};
                        }
                    });
                } else {
                    const selectedLots = this.globalRegistry.systemData.lots.filter((lot: LotModel) => {
                        return farm.id === lot.farm;
                    });
                    treeFarms[farm.id] = {};
                    selectedLots.forEach((lot: LotModel) => {
                        treeFarms[farm.id][lot.id] = null;
                    });
                    this.TREE_DATA = Object.assign({}, treeFarms);
                }
            });
        });
        this.initialize();
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    public buildFileTree(value: any, level: number): any {
        const data: any[] = [];
        // eslint-disable-next-line guard-for-in
        for (const k in value) {
            const v = value[k];
            const node = new TodoItemNode();
            node.item = `${k}`;
            if (v === null || v === undefined) {
                // no action
            } else if (typeof v === 'object') {
                node.children = this.buildFileTree(v, level + 1);
            } else {
                node.item = v;
            }
            data.push(node);
        }
        return data;
    }
}


@Component({
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
    providers: [CheckListDatabase],
    animations: [
        trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
            // Set the duration to 5seconds and delay to 2 seconds
            // params: { timing: 3}
        }))])
    ]
})
export class BaseComponent implements AfterViewInit, OnInit, OnDestroy {

    public hoverColor: string;
    public sidebarColor: string;
    public plantationFilter: string;
    // public right_side_bar: boolean;
    public mapIsOpened = true;
    public mapShiftedOnce: boolean;
    public mapShiftedTwice: boolean;
    public background: string;
    public isProfileOpen = false;
    public isSelectDialogOpen = false;
    public flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
    public nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
    public treeControl: FlatTreeControl<TodoItemFlatNode>;
    public treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
    public dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
    public checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    public collapseSidebar: boolean;
    public flatFarms: {} = {};
    public flatLots: {} = {};
    public urlFarmsSelected = [];
    public size = null;
    private subscriptions: Array<Subscription> = [];
    title = 'app';
    userId = 999;
    public currentUser;
    public leftMenuItems: Menu[];
    public allowed = false;
    public mediaUrl = environment.mediaUrl;

    constructor(public navServices: NavService,
                public globalRegistry: GlobalRegistryService,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                public customizer: CustomizerService,
                private _database: CheckListDatabase,
                private widgetService:WidgetsService,
                private mapService: MapService,
                public sensorService: SensorService,
                public userService: UserService,
                private cdRef: ChangeDetectorRef
                ) {
                    this.subscriptions.push(this.navServices.collapseSidebar.subscribe((collapse: boolean) => {
            this.collapseSidebar = collapse;
        }));
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
            this.urlFarmsSelected = data.farms;
        }))
        this.hoverColor = this.navServices.hoverColor;
        this.background = this.navServices.sidebarBackgroundColor;
        this.sidebarColor = this.navServices.sidebarBackgroundColor;
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        _database.dataChange.subscribe((data: TodoItemNode[]) => {
            this.globalRegistry.systemData.farms.forEach((farm:FarmModel)=>{
                this.flatFarms[farm.id] = farm.name;
            })
            this.globalRegistry.systemData.lots.forEach((lot:LotModel)=>{
                this.flatLots[lot.id] = lot.name
            })
            this.dataSource.data = data;
        });
        // this.clearPosParam();
        this.navServices.items.subscribe((menuItems: Menu[]) => {
        this.userService.getUser().subscribe(response => {
            this.currentUser = response.body.results;
            console.log(this.currentUser);
            this.userService.setCurrentUser(this.currentUser);
            this.userService.setTheme(this.currentUser.profile.data.ui.theme);
            console.log(this.currentUser);
            if(Object.keys(this.currentUser.profile.data).length !== 0) {
            const result = menuItems.filter(arr => this.currentUser.profile.data.allowed.includes(arr.key));
            this.leftMenuItems = result;
            this.allowed = this.leftMenuItems.some((l) => l.key === 'chat');
            }})
        });
    }


    public ngAfterViewInit(): void {
        setTimeout(() => {
            feather.replace();
        });
    }

    public setFilterValue(): void {
        this._database.plantationFilter.next(this.plantationFilter);
        this._database.getTreeData();
        this.fetchQueryParams();
    }

    public isExpandable = (node: TodoItemFlatNode) => node.expandable;

    public hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

    public getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
        return ofObservable(node.children);
    };

    ngOnInit(): void {
        this.subscriptions.push(this.widgetService.getSize.subscribe(size => {
            // console.log('The size is: '+ size);
            this.size = 'size' + size;
    
            // Manually trigger change detection
            this.cdRef.detectChanges();
        }));
    
        if (window.innerWidth <= 767) {
            this.mapIsOpened = false;
            this.mapService.hideMap();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    public transformer = (node: TodoItemNode, level: number) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.item === node.item
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ? this.nestedNodeMap.get(node)!
            : new TodoItemFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };


    //   @HostListener('document:click', ['$event'])
    //   public clickedOutside(event): void {
    //   	// click outside Area perform following action
    //   	document.getElementById('outer-container').onclick = function (e) {
    //   		e.stopPropagation()
    //   		if (e.target != document.getElementById('search-outer')) {
    //   			document.getElementsByTagName("body")[0].classList.remove("offcanvas");
    //   		}
    //   		if (e.target != document.getElementById('outer-container')) {
    //   			document.getElementById("canvas-bookmark").classList.remove("offcanvas-bookmark");
    //   		}
    //   		if (e.target != document.getElementById('inner-customizer')) {
    //   			document.getElementsByClassName("customizer-links")[0].classList.remove("open")
    //   			document.getElementsByClassName("customizer-contain")[0].classList.remove("open")
    //   		}
    //   	}
    //   }

    // Unused code
    // public getRouterOutletState(outlet): void {
    //     return outlet.isActivated ? outlet.activatedRoute : '';
    // }

    // public rightSidebar($event): void {
    //     this.right_side_bar = $event;
    // }

    public onMapToggle(mapIsOpened: boolean): void {
        this.mapIsOpened = mapIsOpened;
    }

    public getLevel = (node: TodoItemFlatNode) => node.level;

    public getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    public checkRootNodeSelection(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every((child: TodoItemFlatNode) => {
            return this.checklistSelection.isSelected(child);
        });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    public checkAllParentsSelection(node: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    public todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

      /** Whether all the descendants of the node are selected */
  public descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length>0?descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    ):false;
  }

  /** Whether part of the descendants are selected */
  public descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  public todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

    public checkAllNodes(): void {
        if (
            this.checklistSelection.selected.length ===
            this.treeControl.dataNodes.length
          ) {
            this.treeControl.dataNodes.forEach((node: TodoItemFlatNode) => {
              this.checklistSelection.deselect(node);
            });
          } else {
            this.treeControl.dataNodes.forEach((node: TodoItemFlatNode) => {
              if (!this.checklistSelection.isSelected(node)) {
                this.checklistSelection.select(node);
              }
            });
          }
    }

    public searchSubmit(): void {
        const originalParams = {};
        const queryParams: {} = Object.assign({}, originalParams);
        let farms: number[] = [];
        let lots: number[] = [];
    
        const selectedFarms = this.globalRegistry.systemData.farms.filter(
          (farm: FarmModel) =>
            this.checklistSelection.selected.some(
              (node: TodoItemFlatNode) =>
                node.level === 0 && node.item === farm.id.toString()
            )
        );
        farms = selectedFarms.map((farm: FarmModel) => farm.id);
    
        lots = this.globalRegistry.systemData.lots
          .filter((lot: LotModel) =>
            this.checklistSelection.selected.some((node: TodoItemFlatNode) => {
              if (node.level === 1 && node.item === lot.id.toString()) {
                const parent = this.getParentNode(node);
                if (!farms.includes(+parent.item)) {
                  const lotFarm = this.globalRegistry.systemData.farms.find(
                    (farm: FarmModel) => farm.id.toString() === parent.item
                  );
                  farms.push(lotFarm.id);
                }
                return true;
              }
              return false;
            })
          )
          .map((lot: LotModel) => lot.id);
    
        queryParams["farms"] = farms && farms.length > 0 ? farms : undefined;
        queryParams["lots"] = lots && lots.length > 0 ? lots : undefined;
        console.log(queryParams);
        this.sensorService.setLots(queryParams['lots']);
        this.sensorService.setFarms(queryParams['farms']);
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams,
        });
        localStorage.setItem("queryParams",JSON.stringify(queryParams));
        
      }
    
      public fetchQueryParams(): void {
        this.treeControl.dataNodes.forEach((nodesFilter: TodoItemFlatNode) => {
          this.checklistSelection.deselect(nodesFilter);
        });
        this.activatedRoute.queryParamMap.subscribe((params: Params) => {
          // const farmParams: any[] = Array.isArray(params.params.farms)
          //     ? params.params.farms
          //     : [params.params.farms];
          // const filteredFarms = this.globalRegistry.systemData.farms.filter(
          //     (farm: FarmModel) => farmParams.includes(farm.id.toString())
          // );
          // filteredFarms.forEach((farms: FarmModel) => {
          //     this.treeControl.dataNodes.forEach((nodesFilter: TodoItemFlatNode) => {
          //     if (nodesFilter.item === farms.name) {
          //         this.checklistSelection.select(nodesFilter);
          //         this.checklistSelection.select(
          //         ...this.treeControl.getDescendants(nodesFilter)
          //         );
          //     }
          //     });
          // });
    
          const lotParams: any[] = Array.isArray(params.params.lots)
            ? params.params.lots
            : [params.params.lots];
          const filteredLots = this.globalRegistry.systemData.lots.filter(
            (lot: LotModel) => lotParams.includes(lot.id.toString())
          );
          filteredLots.forEach((lot: LotModel) => {
            this.treeControl.dataNodes.forEach((nodesFilter: TodoItemFlatNode) => {
              if (nodesFilter.item === lot.id.toString() && nodesFilter.level === 1) {
                this.checklistSelection.select(nodesFilter);
                this.checkAllParentsSelection(nodesFilter);
              }
            });
          });
        });
      }

    // Clear Query params
    public clearPosParam(): void {
        this.router.navigate(
            ['.'],
            {relativeTo: this.activatedRoute, queryParams: {}}
        );
    }

    public toggleSearchDialog(event: boolean): void {
        this.isSelectDialogOpen = event;
    }

    public clearInput(): void {
        this.plantationFilter = undefined;
        this.setFilterValue();
    }

    public onMapShift(mapIsShifted: number): void {
        switch (mapIsShifted) {
            case 0: {
                this.mapShiftedTwice = false;
                this.mapShiftedOnce = false;
                break;
            }
            case 1: {
                this.mapShiftedOnce = true;
                break;
            }
            case 2: {
                this.mapShiftedTwice = true;
                break;
            }
            default: {
                this.mapShiftedTwice = false;
                this.mapShiftedOnce = false;
                break;
            }
        }
    }

    public toggleProfile($event: boolean): void {
        this.isProfileOpen = $event;
    }
}
