import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'posts-card',
  templateUrl: './posts-card.component.html',
  styleUrls: ['./posts-card.component.scss']
})
export class PostsCardComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public post;
  @Input()
  public crops;
  @Input()
  public genericTypes;
  @Input() public persons: Array<PersonModel>;
  @Input()
  public images;
  @Input() expanded: boolean;
  @Output()
  public deletePost: EventEmitter<number> = new EventEmitter();
  public translatedNames:any = [];
  public langString: string;
  public subscription: Subscription = new Subscription();
  public bgColor: string;
  public person: PersonModel;
  public mediaUrl = environment.mediaUrl;
  public placeholderImg = '../../../../../../assets/images/placeholder.gif';

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, public typesService: TypesService, public companyService: CompanyService) { }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
    this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
        this.langString = lang;
        }
     }));
    this.translatedNames = this.globalRegistry.systemData.translations;
    if(this.post.post_type === 'notes') {
      this.bgColor = 'rgba(43, 103, 76, 0.2)';
    } 
    if(this.post.post_type === 'alerts') {
      this.bgColor = 'rgba(241, 68, 68, 0.24)';
    }
    if(this.post.post_type === 'activities') {
      this.bgColor = 'rgba(236, 212, 2, 0.31)';
    }
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'images') && this.images) {
      const images = []
      if(this.post.images.length) {
        this.post.images.forEach(image => {
          // const bool = this.images.some(i => i.id === image);
          images.push(image);
        });
        this.post.images = images;
        // this.post.images.forEach(element => {
        //   this.getImage(element);
        // });
      }
    }
  }

  public getImage(id) {
    if(id && id!=0) {
      this.companyService
        .getCompanyImage(id)
        .subscribe(data => {
          console.log(data);
        })
    }
      console.log(this.images)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
