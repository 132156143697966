<div class="widget_categories">
  <!-- <div class="add_circle" (click)="showAddCategoryModal()">
    <div class="rounded-circle"></div>
</div> -->
<fap-round-button (clicked)="showAddCategoryModal()" [backgroundColor]="'tertiary'"></fap-round-button>
<div class="scrollable_area">
  <ng-container>
    <button *ngFor="let button of widgetCategories; let i = index" [class.active]="selectedButton === button" (click)="toggleSelect(button)" [style.order]="button?.order">
      {{button?.name_t ? getTranslation(button?.name_t) : button.name}}
    </button>
  </ng-container>
</div>
  <!-- <ng-container *ngIf="screenSize==='mobile'">
    <div class="form-groups">
      <mat-form-field class="w-100">
        <mat-label>Category</mat-label>
        <mat-select [(ngModel)]="selectedButton" [ngModelOptions]="{standalone: true}" class="select" placeholder="Category" (selectionChange)="toggleSelect($event.value)">
            <div class="scroll_wrap">
              <mat-option *ngFor="let category of widgetCategories" [value]="category">
                {{category?.name}}
              </mat-option>
            </div>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container> -->
 </div>
<div class="bgrow" style="position: relative; width: 100%; background: #e0e0e0; min-height: calc(100vh - 87px);" [ngClass]="selectedButton?.map_resize ? '' : 'active' ">
<ngx-masonry [options]="options" [ordered]="false">  
<div ngxMasonryItem *ngFor="let widget of filteredWidgets" class="example-box {{widget?.type?.uniqueid}} {{widget.sizeSmall}} {{widget.sizeMedium}} {{widget.sizeLarge}} {{widget.bgColor}}" style="margin: 5px 2.5px;" [style.order]="widget.order">
  <fap-table-widget
    *ngIf="widget?.type?.uniqueid === 'table' && widget.view == selectedButton?.id"
    [widget]="widget"
    (emitShow)="showModal(widget)"
    style="display: block; width: 100%;"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [bgColor]="widget.bgColor"
    [langString]="langString"
  >
  </fap-table-widget>
  <fap-crop-eye-simple-widget
  *ngIf="widget?.type?.uniqueid === 'cropeyesimple' && widget.view == selectedButton?.id"
  [widget]="widget"
  (emitShow)="showModal(widget)"
  style="display: block; width: 100%; overflow: hidden;"
  [objects]="objects"
  [sensors]="sensors"
  [unitTypes]="unitTypes"
  [langString]="langString"
>
</fap-crop-eye-simple-widget>
  <fap-water-content-widget
    *ngIf="widget?.type?.uniqueid === '*lux5GMLWat' && widget.view == selectedButton?.id"
    [widget]="widget"
    (emitShow)="showModal(widget)"
    style="display: block; width: 100%;"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [langString]="langString"
  >
  </fap-water-content-widget>
<fap-satellite-widget
*ngIf="widget?.type?.uniqueid === '*lux5GSat' && widget.view == selectedButton?.id"
[widget]="widget"
(emitShow)="showModal(widget)"
style="display: block; width: 100%;"
[objects]="objects"
[sensors]="sensors"
[unitTypes]="unitTypes"
[langString]="langString"
>
</fap-satellite-widget>

  <fap-image-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'image' && widget.view == selectedButton?.id"
    [widget]="widget"
    [notes]="notes"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [sensors]="sensors"
    style="display: block; width: 100%;"
    [langString]="langString"
  ></fap-image-widget>

  <fap-soil-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'soil' && widget.view == selectedButton?.id"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [widget]="widget"
    style="display: block; width: 100%;"
    [langString]="langString"
  >
  </fap-soil-widget>

  <fap-image-slider-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'cropeye' && widget.view == selectedButton?.id"
    [widget]="widget"
    [notes]="notes"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [sensors]="sensors"
    style="display: block; width: 100%; overflow: hidden;"
    [langString]="langString"
  ></fap-image-slider-widget>
  <!-- <fap-crop-eye-widget *ngIf="widget?.type?.uniqueid === 'cropeye'" class="mb-5" [widget]="widget"></fap-crop-eye-widget> -->
  <fap-alert-widget
    (emitShow)="showModal(widget)"
    *ngIf="(widget?.type?.uniqueid === 'fungal_alert' || widget?.type?.uniqueid === 'pest_alert' || widget?.type?.uniqueid === 'pathogen_alert') && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [sensors]="sensors"
    [crops]="crops"
    [langString]="langString"
  ></fap-alert-widget>
  <fap-weed-control-widget
  (emitShow)="showModal(widget)"
  *ngIf="widget?.type?.uniqueid === 'herbicide' && widget.view == selectedButton?.id"
  [widget]="widget"
  style="display: block; width: 100%;"
  [objects]="objects"
  [unitTypes]="unitTypes"
  [crops]="crops"
  [sensors]="sensors"
  [langString]="langString"
  >
  </fap-weed-control-widget>
  <fap-chart-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'graph' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [sensors]="sensors"
    [langString]="langString"
  ></fap-chart-widget>
  <graph-range-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'graph_range' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [langString]="langString"
  ></graph-range-widget>
  <graph-percentage-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'graph_percentage' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [devices]="devices"
    [langString]="langString"
  ></graph-percentage-widget>
  <fap-carousel-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'images_list' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [langString]="langString"
  ></fap-carousel-widget>
  <fap-bin-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'bins' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [sensors]="sensors"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [langString]="langString"
  ></fap-bin-widget>
  <fap-sensor-value-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'sensor_value' && widget.view == selectedButton?.id"
    [widget]="widget"
    [notes]="notes"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [sensors]="sensors"
    style="display: block; width: 100%;"
    [langString]="langString"
  ></fap-sensor-value-widget>

  <fap-overview-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'overview' && widget.view == selectedButton?.id"
    [widget]="widget"
    [notes]="notes"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [devices]="devices"
    style="display: block; width: 100%;"
    [langString]="langString"
  ></fap-overview-widget>

  <fap-map-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'map' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%"
    [sensors]="sensors"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [langString]="langString"
  ></fap-map-widget>

  <fap-activities-list-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'activities' && widget.view == selectedButton?.id"
    [widget]="widget"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [activities]="activities"
    [activityTypes]="activityTypes"
    [devices]="devices"
    [persons]="persons"
    (emitScroll)="scrollActivities()"
    (refreshActivitiesList)="getActivities()"
    style="display: block; width: 100%;"
    [langString]="langString"
  ></fap-activities-list-widget>

  <fap-pool-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'pool' && widget.view == selectedButton?.id"
    [widget]="widget"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [devices]="devices"
    [editValue]="true"
    [triggerEditForm]="triggerEditForm"
    (emitDelete)="deletePool($event)"
    (emitTriggerEditForm)="setEmitTriggerEditForm($event)"
    style="display: block; width: 100%;"
    [langString]="langString"
  ></fap-pool-widget>
  <fap-weather-forecast-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'weather_forecast' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [sensors]="sensors"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [langString]="langString"
  ></fap-weather-forecast-widget>
  <fap-device-info-widget
    (emitShow)="showModal(widget)"
    *ngIf="widget?.type?.uniqueid === 'device_info' && widget.view == selectedButton?.id"
    [widget]="widget"
    style="display: block; width: 100%;"
    [objects]="objects"
    [unitTypes]="unitTypes"
    [langString]="langString"
  ></fap-device-info-widget>

  <fap-devices-widget
  *ngIf="widget?.type?.uniqueid === 'devices' && widget.view == selectedButton?.id"
    (emitShow)="showModal(widget)"
    (refreshSensorGroupTypes)="refreshSensorGroupTypes.emit($event)"
    (setTriggerSensorGroupTypes)="triggerSensorGroupTypes = $event"
    (currentView)="updateWidgetCustom($event)"
    [widget]="widget"
    [objects]="objects"
    [sensors]="sensors"
    [unitTypes]="unitTypes"
    [activities]="activities"
    [activityTypes]="activityTypes"
    [devices]="devices"
    [langString]="langString"
    [bgColor]="widget?.bgColor"
    [triggerSensorGroupTypes]="triggerSensorGroupTypes"
    [sensorGroupTypes]="sensorGroupTypes"
    [allDeviceColumns]="allDeviceColumns"
    [profiles]="profiles"
    style="display: block; width: 100%;"
  ></fap-devices-widget>
</div>
</ngx-masonry>

<fap-modal
  [inputIsLarge]="true"
  #addEditWidgetModal
  [titleColor]="'#ffffff'"
  [showFooter]="false"
  [inputTitle]="
    !selectedWidget
      ? ('widget.addWidget' | translate)
      : ('widget.editWidget' | translate)
  "
>
  <fap-add-edit-widget
    (addWidget)="addWidget.emit($event); addEditWidgetModal.hideModal()"
    (updateWidget)="updateWidget.emit($event); addEditWidgetModal.hideModal()"
    (deleteWidget)="deleteWidget.emit($event); addEditWidgetModal.hideModal()"
    (cancel)="addEditWidgetModal.hideModal()"
    (editForm)="editForm($event)"
    [sensors]="sensors"
    [widget]="selectedWidget"
    [lots]="lots"
    [objects]="objects"
    [view]="selectedButtonId"
    [unitTypes]="unitTypes"
    [widgetTypes]="widgetTypes"
    [sensorGroupTypes]="sensorGroupTypes"
    [sensorGroupview]="currentView"
    [widgetsLength]="filteredWidgets ? filteredWidgets.length : 1"
  ></fap-add-edit-widget>
</fap-modal>
</div>

<fap-modal
[inputIsSmall]="true"
  #addEditWidgetCategoryModal
  [titleColor]="'#ffffff'"
  [inputTitle]="'widget.addEditWidgetCategory' | translate"
  [showFooter]="false"
>
  <form [formGroup]="viewForm" class="viewForm">
    <div class="row">
      <mat-form-field class="col-6" (click)="addEditTranslation('notes.name')">
        <mat-label class="col-form-label">{{"notes.name" | translate}}</mat-label>
        <input matInput formControlName="name" readonly #nameInput/>
        <mat-error *ngIf="viewForm.controls['name'].errors?.required">
            <strong>{{
                "auth.validation.requiredField" | translate
            }}</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" style="padding-right: 0">
        <mat-label>{{"widget.order" | translate}}</mat-label>
        <input matInput [placeholder]="'widget.order' | translate" type="number" formControlName="order">
    </mat-form-field>
    </div>
  <div class="row">
    <div class="col-6 d-flex align-items-center">
      <input id="map_resize" type="checkbox" formControlName="map_resize">
      <label class="mb-0 ml-2" for="map_resize">{{ 'widget.mapResize' | translate }}</label>
    </div>
    <mat-form-field class="col-6">
      <mat-label>{{ "widget.size" | translate }}</mat-label>
      <mat-select
          formControlName="size"
          name="size"
      >
          <div class="scroll_wrap">
              <mat-option
                  *ngFor="let size of sizes"
                  [value]="size"
              >
                  {{ size }}
              </mat-option>
          </div>
      </mat-select>
      <mat-error *ngIf="viewForm.controls['size'].errors?.required">
          <strong>{{
              "auth.validation.requiredField" | translate
          }}</strong>
      </mat-error>
  </mat-form-field>
  </div>
  <div class="action_btns">
    <i class="fa fa-trash"(click)="deleteView(selectedButton.id); addEditWidgetCategoryModal.hideModal(); viewForm.reset(); $event.stopPropagation()" title="delete view"></i>
    <div class="btns">
      <button class="btn mr-2" (click)="submitView()">{{ 'translations.save' | translate }}</button>
      <button class="btn" (click)="addEditWidgetCategoryModal.hideModal(); viewForm.reset()">{{ 'translations.cancel' | translate }}</button>
    </div>
</div>
  </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
  <add-edit-translations 
      [obj]="convertKeysToCamelCase(this.view)" 
      [label]="label"
      [prefix]="'VIEW_'" 
      [translation]="translation"
      [group]="'types'"
      (submitTranslation)="onAddUpdateTranslation($event)" 
  >
  </add-edit-translations>
</fap-modal>