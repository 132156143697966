import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges } from "@angular/core";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
} from "ng-apexcharts";
import { SensorModel } from "../../../core/models/sensor/sensor.model";
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import * as moment from "moment";
import { Subscription } from 'rxjs';
import { ObjectModel } from "../../../core/services/api/objects/object.service";
import { UnitTypeModel } from "../../../core/services/api/unit-type/unit-type.service";
import { NavService } from "../../services/nav.service";
import { LocalStorageService } from "../../../core/services/localStorage/local-storage.service";
import { CacheResolverService } from "../../../core/services/api/cache/cache-resolver.service";
import localJson from './data.json'

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  colors:any; //ApexColors
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: any;
  grid: any;
};

interface SensorData {
  id: number;
  color: string;
  graphType: string;
}

@Component({
  selector: "fap-chart-widget",
  templateUrl: "./fap-chart-widget.component.html",
  styleUrls: ["./fap-chart-widget.component.scss"],
})
export class FapChartWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() sensors: SensorModel[] = [];

  @Output()
  public emitShow:EventEmitter<boolean> = new EventEmitter<boolean>();

  public chartOptions: Partial<ChartOptions>;
  public localSensors = [];
  public finalSensorList = [];
  public loadedData = [];
  public newData;
  public seriesData = [];
  public toggleData = [];
  public listDate = [];
  public fromDate;
  public toDate;
  public agg;
  public toggleMain = false;
  public toggleSub = false;
  public cssClass = '';
  public randomId;
  public subscriptions: Array<Subscription> = [];
  public dates =[];
  public translatedNames: any = [];
  @Input() public langString: string;
  // apiCalled = false;
  public isLoading = false;
  public noData = false;
  public showMinMax = false;
  public localSensorData: any = [];
  public showLegend = false;

  constructor(public globalRegistry:GlobalRegistryService,public sensorService:SensorService, public navService: NavService, private cacheService: CacheResolverService, private localStorageService: LocalStorageService) {
    this.chartOptions = {
      series: this.seriesData,
      chart: {
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
          tools: {
            download: false,
          }
        },
        stackType: 'normal'
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: "10px",
        }
      },
      stroke: {
        width: [2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: this.dates,
        tickAmount: 5,
        labels: {
          formatter: function(value) {
            return value
          }
        }
      },
      grid: {
        clipMarkers: false
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
              fontSize: '10px'
            },
            show: false,
          },
          title: {
            text: "",
            style: {
              color: "#008FFB",
              fontSize: '10px'
            },
          },
          tooltip: {
            enabled: true,
          },
        }
      ],
      tooltip: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
          fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: "left",
        show: this.showLegend,
        fontSize: "10px"
      },
    };
  }

  toggleLegend() {
    this.showLegend = !this.showLegend;
    this.getRealTimeData();
  }

  ngOnInit() {
    this.randomId = Math.random();
    this.getFromDate();
    this.getToDate();
    this.getAgg();
   
    this.subscriptions.push(this.sensorService.getName.subscribe(res => {
      if(res) {
        this.seriesData = [];
        this.loadedData = [];
        this.getRealTimeData();
        // this.apiCalled = false;
      }
    }));
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.localStorageService.watchTimezone().subscribe(data => {
          this.getRealTimeData();
        })
  }

  getFromDate() {
    this.subscriptions.push(this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    }));
  }

  getToDate() {
    this.subscriptions.push(this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    }));
  }

  getAgg() {
    this.subscriptions.push(this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    }));
  }

  getTranslation(translation) {
    const t = this.translatedNames.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
    }
}

  ngOnDestroy () {
    this.subscriptions.forEach(e => {
      e.unsubscribe();
    });
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  showMenu() {
    this.toggleSub = !this.toggleSub
    this.getRealTimeData();
    if(this.toggleSub == true) {
      this.chartOptions = {...this.chartOptions, ...{
        chart: {
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: true,
            }
          },
        },
        legend: {
          show: this.showLegend,
        }
      }};
    } else {
      this.chartOptions = {...this.chartOptions, ...{
        chart: {
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            }
          },
        },
        legend: {
          show: this.showLegend,
        }
      }};
    }
  }

  hideMain() {
    this.toggleMain = false
  }

  ngOnChanges() {
    this.getRealTimeData();
  }

  getLocalSensors(sensorData: SensorData[]) {
    const objects = Object.values(this.objects);
    const unitTypes = Object.values(this.unitTypes);
    
    return sensorData.map(sensor => {
        const sensorId = sensor.id;
        const sensorObject = this.sensors.find(s => s.id === sensorId);
        if (!sensorObject) return null;
        const sensorUnitTypes = unitTypes.find(unitType => unitType.id === sensorObject.unitType);
        const sensorObjectData = objects.find(obj => obj.id === sensorObject.objectId);

        return {
            unitType: sensorUnitTypes,
            object: sensorObjectData,
            sensor: sensorObject,
            color: sensor.color,
            graphType: sensor.graphType,
        };
    }).filter(Boolean);
} 

public async getRealTimeData() {
  this.isLoading = true;
  this.loadedData = [];
  this.seriesData = [];
  console.log(this.widget);
  this.cssClass = this.widget['sizeClass'];
  setTimeout(async () => {
    // const userTimezone = this.timezoneService.getUserTimezone(localStorage.getItem('timezone')) || 'UTC';
      try {
          const sensorIds = this.widget["settings"]["sensors"].map(
              (sensor) => sensor?.id
          );
          let url = this.sensorService.getUrl('adv_drill_data/sensors='+sensorIds.toString()+'&from='+this.fromDate+'&agg_by='+this.agg+'&to='+this.toDate+'&agg_data=mean,sum,min,max&compare=0');
          const data1 = await this.sensorService.advdrillData({ sensors: sensorIds.toString(), from: this.fromDate, agg_by: this.agg, to: this.toDate, agg_data: 'mean,sum,min,max', compare: 0 }).toPromise();
          this.cacheService.delete(url);
          const Newdata = data1.results;
          // const Newdata = localJson.results;
          const data = Newdata['0'];
          console.log(data)
          if (data.labels.length === 0) {
              this.noData = true;
          } else {
              this.noData = false;
          }
          setTimeout(async () => {
              const arr = Object.keys(data).map(function (key) {
                  return { ...data[key], sensor: key };
              });
              arr.pop();
              const datesData = Object.keys(data).map(function (key) {
                  return { [key]: { ...data[key] } };
              });
              const dates = datesData.pop();
              const datesArr = Object.values(dates.labels);
              const seriesDates = [];
              datesArr.forEach((element: any) => {
                  const date = new Date(element * 1000);
                  seriesDates.push(date.toLocaleString('en-US'));
              });
              this.dates = seriesDates;

              this.localSensorData = this.getLocalSensors(this.widget['settings']['sensors']);
              
              console.log(this.localSensorData)
              await Promise.all(this.localSensorData.map(async (item) => {
                  item.data = arr.find(o1 => o1.sensor == item.sensor.id);
              }));
    
              for (let i = 0; i < this.localSensorData.length; i++) {
                  const aggrSeries = this.localSensorData.map((type) => {
                      return {
                          name: type.data.name ? ' (' + type.data.name + ')' : type.object.extraText + ' (' + type.unitType.unit + ')',
                          type: type.graphType,
                          data: type.data[type.data.aggr_type]
                      };
                  });

                  const minSeries = this.localSensorData.map((type) => {
                      return {
                          name: type.data.name ? ' (' + type.data.name + ') - Min' : type.object.extraText + ' (' + type.unitType.unit + ') - Min',
                          type: type.graphType,
                          data: type.data['min']
                      };
                  });

                  const maxSeries = this.localSensorData.map((type) => {
                      return {
                          name: type.data.name ? ' (' + type.data.name + ') - Max' : type.object.extraText + ' (' + type.unitType.unit + ') - Max',
                          type: type.graphType,
                          data: type.data['max']
                      };
                  });

                  const toggleSeries = this.localSensorData.map((type) => {
                      return {
                          name: type.data.name ? ' (' + type.data.name + ')' : type.object.extraText + ' (' + type.unitType.unit + ')',
                          type: type,
                          data: type.data
                      };
                  });

                  if (this.showMinMax && this.agg != 'hour') {
                      this.seriesData = [...aggrSeries, ...minSeries, ...maxSeries];
                  } else {
                      this.seriesData = aggrSeries
                  }
                  this.toggleData = toggleSeries;
              }

              this.seriesData.forEach(element => {
                  element.name = element.name.replace(/null/g, "");
              });

              this.chartOptions.series = this.seriesData;
              this.chartOptions.colors = this.localSensorData.map((type) => type.color);
              this.chartOptions.legend.show = this.showLegend;

              if (this.chartOptions.series.length === 1) {
                  this.chartOptions.chart.type = this.seriesData[0].type;
              }

              const differentYear = false;
              this.chartOptions.xaxis.categories = this.dates;

              if (this.agg === 'hour') {
                  this.chartOptions.xaxis.labels = {
                      formatter: val => {
                          const formatString = differentYear ? 'DD MMM YYYY, HH' : 'DD MMM, HH';
                          return moment(val).format(formatString);
                      }
                  };
              } else if (this.agg === 'day') {
                  this.chartOptions.xaxis.labels = {
                      formatter: val => {
                          const formatString = differentYear ? 'DD MMM YYYY' : 'DD MMM';
                          return moment(val).format(formatString);
                      }
                  };
              } else if (this.agg === 'month') {
                  this.chartOptions.xaxis.labels = {
                      formatter: val => moment(val).format('MMM YYYY')
                  };
              }

              function parentWidth(elem) {
                  return elem.parentElement.clientHeight;
              }
              this.chartOptions.chart.height = parentWidth(document.getElementById('parent' + this.randomId));
              if (this.showMinMax && this.agg != 'hour') {
                  const tooltip = {
                      enabled: true,
                      custom: ({ dataPointIndex }) => {
                          let tooltipContent = '';
                          for (let i = 0; i < this.toggleData.length; i++) {
                              const currentSeries = this.toggleData[i];
                              const min = currentSeries.data.min[dataPointIndex];
                              const max = currentSeries.data.max[dataPointIndex];
                              const val = currentSeries.data[currentSeries.data.aggr_type][dataPointIndex];
                              tooltipContent += `
                                  <div class="custom-apex-tooltip custom-apex-theme-light">
                                      <div class="custom-apex-tooltip-title">
                                          <span class="custom-apex-tooltip-marker" style="background-color: ${currentSeries.type.color};"></span>
                                          ${currentSeries.name}
                                      </div>
                                      <div class="custom-apex-tooltip-series-group">
                                          <div class="custom-apex-tooltip-text">
                                              <div class="custom-apex-tooltip-y-group">
                                                  <span class="custom-apex-tooltip-text-label">${currentSeries.data.aggr_type}: </span>
                                                  <span class="custom-apex-tooltip-text-value">${val ? val : 0}</span>
                                              </div>
                                              <div class="custom-apex-tooltip-y-group">
                                                  <span class="custom-apex-tooltip-text-label">Min: </span>
                                                  <span class="custom-apex-tooltip-text-value">${min ? min : 0}</span>
                                              </div>
                                              <div class="custom-apex-tooltip-y-group">
                                                  <span class="custom-apex-tooltip-text-label">Max: </span>
                                                  <span class="custom-apex-tooltip-text-value">${max ? max : 0}</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>`;
                          }

                          return tooltipContent;
                      }

                  }
                  this.chartOptions.tooltip = tooltip;
              } else {
                  const tooltip = {
                      enabled: true
                  }
                  this.chartOptions.tooltip = tooltip;
              }
              // console.log(this.seriesData);
              const dashArray = [];
              const opacity = [];

              for (const series of this.seriesData) {
                  if (series.name.includes('- Min') || series.name.includes('- Max')) {
                      dashArray.push(1);
                      opacity.push(0.5);
                  } else {
                      dashArray.push(0);
                      opacity.push(1);
                  }
              }

              // console.log(dashArray);

              const stroke = {
                  width: 3,
                  curve: "smooth",
                  dashArray: dashArray
              }
              this.chartOptions.stroke = stroke
              this.chartOptions.fill = { opacity }
              console.log(this.localSensorData);
              this.chartOptions.yaxis = this.localSensorData.map(
                  (type, index) => ({
                      axisTicks: {
                          show: true,
                      },
                      axisBorder: {
                          show: true,
                          color: type.color,
                      },

                      seriesName: type.data.name ? type.data.name : type.object.extraText + ' (' + type.unitType.unit + ')',
                      show: type.data.show,
                      title: {
                          text: type.data.title ? type.data.title : type.object.extraText + ' (' + type.unitType.unit + ')',
                          style: {
                              color: type.color,
                          },
                      },
                      tooltip: {
                          enabled: true,
                      },
                      opposite: index % 2 ? true : false,
                      min: type.data.rangeGraph[0],
                      max: type.data.rangeGraph[1]
                  })
              );
          })
          this.sensorService.setName(false);
          this.isLoading = false;
      } catch (error) {
          console.error('Error fetching data:', error);
          this.isLoading = false;
      }
  }, 1000)
}

  public tglValues() {
    this.showMinMax = !this.showMinMax;
    this.getRealTimeData();
  }

  public getLotName(lotId: number = this.widget.objectId) {
    if(lotId != 0) {
        const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
        return lot ? lot.name : '';
    }
  }

}
