import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { FapAPIRequestOptions } from '../fap-api-request.options';

@Injectable()
export class EntityService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/entity/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getEntities(params?): Observable<ResponseModel<{}>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'adv_data/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),false,
            false
        ).pipe(take(1));
    }

    public getEntitiesColumns(params?): Observable<ResponseModel<{}>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'adv_data/columns/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),false,
            false
        ).pipe(take(1));
    }

    public postColumns(params: any, sensorGroupTypeId): Observable<ResponseModel<{}>> {
        return this.mapRequest(
          this._http.put(this.url + "adv_data/columns/?filter=selected&group="+sensorGroupTypeId,
              params,
              FapAPIRequestOptions.withTokenRequestOptions),
              false,
              true
      ).pipe(take(1));
      }
   
}