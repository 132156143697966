import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavService } from '../../../../../../shared/services/nav.service';
import { AttributeService } from '../../../../../../core/services/api/attribute/attribute.service';
import { GlobalRegistryService } from '../../../../../../core/global-registry/global-registry.service';
import { CacheResolverService } from '../../../../../../core/services/api/cache/cache-resolver.service';
import { EquipmentService } from '../../../../../../core/services/api/equipment/equipment.service';
import { ActivityService } from '../../../../../../core/services/api/activity/activity.service';
import { StockService } from '../../../../../../core/services/api/stock/stock.service';
import { NotesService } from '../../../../../../core/services/api/farm/notes.service';
import { FarmService } from '../../../../../../core/services/api/farm/farm.service';
import { Subscription } from 'rxjs';
import { FapModalComponent } from '../../../../../../shared/partials';
import * as moment from 'moment';
import { UnitModel } from '../../../../../../core/models/units/unit.model';
import { MapsAPILoader } from '@agm/core';
import { FapModalButtonInterface } from '../../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../../../../core/services/api/company/company.service';
import { environment } from '../../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TypesService } from '../../../../../../core/services/api/types/types.service';
import { MapPointInterface } from '../../../../../../shared/layout/fap_main-map/data/map-point.interface';
import { getUnitPipe } from '../../../../../../core/pipes/unit.pipe';
import { DomSanitizer } from '@angular/platform-browser';
declare let google;

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content: string;
  color?: string;
  iconUrl?: string;
}

@Component({
  selector: 'classical-form-field',
  templateUrl: './classical-form-field.component.html',
  styleUrls: ['./classical-form-field.component.scss'],
  providers: [getUnitPipe]
})
export class ClassicalFormFieldComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() field: any;
  @Input() height: any = null;
  @Input() screenSize = 'pc';
  public editValuesForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  @Input() selectedAttr = null;
  @Input() resetForm = false;
  @Input() errorFields: any[] = [];
  public minDate;
    public maxDate;
  @ViewChild('editValuesPopup')
  public editValuesPopup: FapModalComponent;
  public entities = [];
  public limit = 1000;
  public getMore = true;
  public nextToken: { limit: number; offset: number; search: string } = null;
  public entityModel = '';
  public inputType = '';
  public valueType = '';
  public dropdownSettings = {}
  @ViewChild('mys') mys;
  @Output()
  public editValue = new EventEmitter<{ value: any, type: string, validation: boolean }>();
  public langString: string;
  zoom = 8;
  markers:Array<marker> = [];
  public searchControl: UntypedFormControl;
  public mapSearchToggle: boolean;
  public mapTypeControl: boolean;
  @ViewChild('search1', {static: true}) public searchElementRef: ElementRef;
  public coordsArray = [];
  defaultCenter = {lat: 51.673858, lng: 7.815982};
  public fieldImages:Array<number> = [];
  currentCenter = Object.assign({}, this.defaultCenter);
  @ViewChild('locationPopup') locationPopup: FapModalComponent;
  public fieldImagesId: any = [];
  public mediaUrl = environment.mediaUrl;
  imageSrc: string;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public changedImage: boolean;
  public translatedNames = [];
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('nameInput1') nameInput1: ElementRef;
  public translation = null;
  public nameT = null;
  public bounds: google.maps.LatLngBounds;
  public polygons;
  public polylines;
  public mapType = '';
  public mapCoords;
  public mapPolygons;
  public mapPolylines;
  public isEditMode = false;
  public coords;
  @Input() entityInfo: any;
  public selectedChoices: number[] = [];
  public selectedChoiceId: number | null = null;
  public choices = [{id: 1, input: 1, name_t: "VALUE_POOL_POLYAMIDE"}, 
  {id: 2, input: 2, name_t: "VALUE_POOL_CONCRETE"}];
  public label;
  public stringValidation: boolean = false;
  public rowHeight;
  public latestTranslation;

  constructor(
    public navService: NavService,
    public attributeService: AttributeService,
    public globalRegistry: GlobalRegistryService,
    public cacheService: CacheResolverService,
    public formBuilder: UntypedFormBuilder,
    public equipmentService: EquipmentService,
    public activityService: ActivityService,
    public stockService: StockService,
    public notesService: NotesService,
    public farmService: FarmService, public translateService: TranslateService, public companyService: CompanyService, public toastr: ToastrService, public typesService: TypesService, public getUnitPipe: getUnitPipe, private sanitizer: DomSanitizer) {
      this.onResize();
     }

     @HostListener('window:resize', ['$event'])
     onResize() {
       const width = window.innerWidth;
       this.screenSize = width >= 1025 ? 'pc' : (width >= 768 ? 'tablet' : 'mobile');
     }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
    this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
        this.langString = lang;
      }
    }));
    this.getTranslations();
    this.initNewRelationForm();
      // if(this.resetForm) {
        // this.editValuesForm.reset();
      // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('height') && this.height) {
      this.getHeight(this.height);
    }
    if(changes.hasOwnProperty('errorFields') && this.errorFields.length) {
      console.log(this.errorFields);
    }
  }

  getHeight(height): number {
    if(height && height[this.screenSize] && height[this.screenSize]['rows']) {
      this.rowHeight = height[this.screenSize]['rows'] * 46;
      return this.rowHeight
    }
  }

addImage(event, field, type) {
  const imageId = event.value.id;
  if(type === 'multiselect') {
    this.fieldImages = [...this.fieldImages, imageId];
  } else {
    this.fieldImages = [imageId];
  }
  console.log(this.fieldImages);
  this.editValuesForm.get('value').setValue(this.fieldImages);
  console.log(this.editValuesForm.value);
  this.editValue.emit({value: this.fieldImages, type: 'value', validation: field.validation});
}

deleteNoteImage(imgId, field) { 
  console.log(imgId, field);
  this.fieldImages = this.fieldImages.filter((item) => item !== imgId);
  console.log(this.fieldImages);
  this.editValuesForm.get('value').setValue(this.fieldImages);
  console.log(this.editValuesForm.value);
  this.editValue.emit({value: this.fieldImages, type: 'value', validation: field.validation})
}

getTranslations() {
  this.subscriptions.push(
      this.typesService.getTranslations().subscribe((data) => {
          this.translatedNames = data.model;
          return;
      })
  );
}

public createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
  arrayOfObjects.forEach(obj => {
      objectMap.set(obj.id, obj);
      if(obj.children && obj.children.length) {
         this.createObjectMap(obj.children, objectMap);
      }
  });
  return objectMap;
}

getTranslationByKey(key): string {
  this.subscriptions.push(
    this.typesService.getTranslationByKey(key).subscribe(data => {
      if(data) {
        const index = this.globalRegistry.systemData.translations.findIndex(item => item.id === data.body.results.id);
        if (index !== -1) {
          this.globalRegistry.systemData.translations[index] = data.body.results;
          this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
          this.translatedNames = this.globalRegistry.systemData.translations;
          this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
        } else {
          this.globalRegistry.systemData.translations.push(data.body.results);
          this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
          this.translatedNames = this.globalRegistry.systemData.translations;
          this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
        }
      }
    })
  )
  return key;
}

ngAfterViewInit() {
  if(this.mys && this.mys.nativeElement) {
    this.mys.nativeElement.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) { // enter key code
        event.stopPropagation();
      }
    });
  }
  }

  changeValue(event) {
    console.log(event.target.value)
  }

  collectEntities(ev) {
    console.log(ev);
  }

  scrollEntities(filter) {
    console.log(filter);
  }

  public minValueChanged(ev) {
    this.minDate = ev.target.value;
}

public maxValueChanged(ev) {
    this.maxDate = ev.target.value;
}

getTranslation(translation) {
  const t =this.globalRegistry.systemData.translations.filter(trans => {
    return trans.id == translation
  });
  if(t[0]) {
    if(t[0][this.langString] === null || t[0][this.langString] === '') {
      return translation
    } else {
      return t[0][this.langString];
    }
  }else {
      return translation
    }
}

updateMap() {
  console.log(this.field.field.value_type);
  this.locationPopup.showModal();
  // this.polygons = [];
  // this.polylines = [];
  setTimeout(() => {
    this.initNewRelationForm();
  }, 200)
  if(this.field.field.value_type == 'surface' || this.field.field.value_type == 'polyline') {
    this.isEditMode = true;
  } else {
    this.isEditMode = false
  }
}

public centerMapOnMarkers(): void {
  if (!this.markers.length) {
      return;
  }
  this.bounds = new google.maps.LatLngBounds();
  if (this.markers.length) {
      // let latSum = 0;
      // let lngSum = 0;
      this.markers.forEach((point: MapPointInterface): void => {
          // latSum += point.lat;
          // lngSum += point.lng;
          this.bounds.extend(
              new google.maps.LatLng(point.lat, point.lng)
          );
      });
  }

  this.currentCenter.lat += this.markers[0].lat;
  this.currentCenter.lng += this.markers[0].lng;
  // this.zoom += this.markers[0].zoom;
  this.zoom = 10;
}
 
mapClicked($event) {
  this.markers = [];
  this.markers.push({
    lat: $event.coords.lat,
    lng: $event.coords.lng,
    draggable: true,
    content: $event.coords.lat +', '+ $event.coords.lng,
  });
  this.coordsArray = [$event.coords.lat, $event.coords.lng];
  this.editValuesForm.get('value').patchValue(this.coordsArray.toString());
  console.log(this.coordsArray);
}

markerDragEnd(m: marker, $event) {
  console.log("dragEnd", m, $event);
}

mapReady(event) {
  console.log(event);
  this.setCurrentPosition();
}

private setCurrentPosition() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.currentCenter.lat = position.coords.latitude;
      this.currentCenter.lng = position.coords.longitude;
      this.zoom = 15;
    });
  }
}

toggleChoice(choiceName: number) {
  const index = this.selectedChoices.indexOf(choiceName);
  if (index === -1) {
    this.selectedChoices.push(choiceName);
  } else {
    this.selectedChoices.splice(index, 1);
  }
  console.log(this.selectedChoices);
  this.editValuesForm.get('value').setValue(this.selectedChoices);
}

getChoice(item) {
  let string = '';
  if(this.entityInfo && this.entityInfo.fields.length) {
  this.entityInfo.fields.forEach(element => {
    if(element.choices && element.choices.length) {
      element.choices.forEach(e => {
        if(e.id == item) {
          string = this.getTranslation(e.name_t);
        }  
      })
    }
  });
}
  return string;
}

setChoice(choice: any, field) {
  this.selectedChoiceId = choice.id;
  console.log(this.selectedChoiceId);
  this.editValue.emit({value: +choice.input, type: 'value', validation: field.validation})
}

checkBooleanValue(event, field) {
  if(event.checked) {
    this.setValue(1, field);
  } else {
    this.setValue(0, field)
  }
}

  public initNewRelationForm(): void {
    this.editValuesForm = this.formBuilder.group({
        name: [null],
        value: [null],
        unit: [this.field && this.field.field.unit ? this.getUnitPipe.transform(this.field.field.unit, this.globalRegistry.systemData.unitsMap) : '' ],
        info: [this.selectedAttr ? this.selectedAttr.info : null],
        min: [null],
        max: [null],
    });
    console.log('selected attribute', this.selectedAttr);
    this.mapType = this.field ? this.field.field.value_type : 'coords';
    if(this.field && this.selectedAttr) {
      if(this.field.field.value_type === 'string') {
        // this.editValuesForm.get('name').patchValue(this.getTranslation(this.selectedAttr.value.input))
        // console.log(this.selectedAttr.value.input)
        this.selectedChoices = [];
        if(this.field.field.input_type === 'multiselect') {
          this.selectedAttr.value.input.forEach(item => {
            this.toggleChoice(item);
          });
        }
        this.editValuesForm.get('name').patchValue(this.selectedAttr.value.input ? this.selectedAttr.value.input[this.langString] : '')
        // this.translatedNames.forEach(element => {
        //   if(element.id === this.selectedAttr.value[this.langString]) {
        //     console.log(this.langString);
        //     console.log(element[this.langString]);
        //     this.editValuesForm.get('name').patchValue(this.langString ? element[this.langString] : element.en)
        //   }
        // });
      }

      if(this.field.field.value_type === 'string' || this.field.field.value_type === 'boolean' || this.field.field.input_type === 'choice') {
        this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input);
        // if(this.field?.[this.screenSize]?.component === 'label' && !this.latestTranslation && this.editValuesForm && this.editValuesForm.get('value').value) {
        //   this.latestTranslation = this.getTranslationByKey(this.editValuesForm.get('value').value);
        //   console.log(this.latestTranslation);
        // }
      }

      if(this.field.field.value_type ==='image') {
        if(this.selectedAttr.value.input) {
          this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
          this.fieldImages = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.value_type === 'number') {
        if(this.field.field.input_type === 'single') {
          this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
        } else {
          if((this.selectedAttr.value.input && this.selectedAttr.value.input.min)) {
          this.editValuesForm.get('min').patchValue(this.selectedAttr.value.input.min)
        }
        if(this.selectedAttr.value.input && this.selectedAttr.value.input.max) {
            this.editValuesForm.get('max').patchValue(this.selectedAttr.value.input.max)
          }
        }
      }

      if(this.field.field.value_type === 'datetime') {
        if(this.field.field.input_type === 'single') {
          this.editValuesForm.get('value').patchValue(moment(this.selectedAttr.value.input).format(''))
        } else {
          this.editValuesForm.get('min').patchValue(moment(this.selectedAttr.value.input.min).format(''))
          this.editValuesForm.get('max').patchValue(moment(this.selectedAttr.value.input.max).format(''))
        }
      }

      if(this.field.field.value_type === 'coords') {
        this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input.coordinates.toString())
        if(this.selectedAttr && this.selectedAttr.value && this.selectedAttr.value.input) {
          this.coords = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.value_type === 'surface') {
        if(this.selectedAttr.value.input) {
          const pointsString = this.selectedAttr.value.input.map(entry =>
            entry.points.map(point => `${point.lat},${point.lng}`).join(',')
          ).join(';');
          this.editValuesForm.get('value').patchValue(pointsString)
        }
        if(this.selectedAttr && this.selectedAttr.value && this.selectedAttr.value.input) {
          this.polygons = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.value_type === 'polyline') {
        if(this.selectedAttr.value.input) {
          const pointsString = this.selectedAttr.value.input.map(entry =>
            entry.points.map(point => `${point.lat},${point.lng}`).join(',')
          ).join(';');
          this.editValuesForm.get('value').patchValue(pointsString)
        }
        if(this.selectedAttr && this.selectedAttr.value && this.selectedAttr.value.input) {
          this.polylines = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.entity) {
        console.log(this.selectedAttr.value);
        this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
        this.entityModel = this.field.field.entity.model;
        const entity : string = this.entityModel;
        console.log(entity);
        switch (entity) {
            case 'farm':
                this.getFarms();
                break;
            case 'lot':
                this.getLots();
                break;
            case 'equipment':
                this.getEquipments();
                break;
            case 'activity':
                this.getActivities();
                break;
            case 'ingredient':
                this.getIngredients();
                break;
            case 'note':
                this.getNotes();
                break;
            case 'bill':
                this.getBills();
                break;
            case 'post':
                this.getPosts();
                break;
            case 'person':
                this.getPersons();
                break;
            case 'product':
                this.getProducts();
                break;
            case 'simulation':
                this.getSimulations()
                break;
            default:
                console.log("no entities matched");
                break;
        }
        console.log(this.entities)
      }
    }
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.editValuesForm.controls['value'].setValue(translation.id);
    this.editValuesForm.controls['name'].setValue(translation[this.langString]);
    this.editValue.emit({value: translation, type: 'value', validation: this.stringValidation});
    this.addEditTranslationsModal.hideModal();
}

handleTranslationClick(field: any): void {
  let translationKey: string;
  if (field?.field?.name_t) {
      translationKey = this.getTranslation(field.field.name_t);
  } else {
      translationKey = field.name;
  }
  this.addEditTranslation(translationKey);
  this.stringValidation = field.validation;
}

  addEditTranslation(label) {
    // const name = this.nameInput1.nativeElement.value;
    this.label = label;
    const name = this.editValuesForm.controls['value'].value;
    console.log(name);
    const type: any = this.convertKeysToCamelCase(this.editValuesForm.value);
    console.log(type);
    if(!name || name === null || name === '') {
        this.translation = null;
        this.addEditTranslationsModal.showModal();
    } else {
      this.editValuesForm.controls['value'].value
      this.translation = name;
      console.log(this.translation);
      this.addEditTranslationsModal.showModal();
    }
    }

    convertKeysToCamelCase(obj) {
      const camelCaseObj = {};
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
      }
      return camelCaseObj;
    }

  editFieldValue(event) {
    console.log(event.target.value);
  }

  removeItem(id) {
    // let value = this.selectedAttr.value.input.filter(item => item !== id);
    this.selectedAttr.value.input = this.selectedAttr.value.input.filter(item => item !== id);
    this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input);
  }

  ngOnDestroy(){
    this.subscriptions.forEach(x=>x.unsubscribe());
    localStorage.removeItem('fap-attribute-relations');
    this.entities = [];
    this.inputType = null;
    this.valueType = null;
    this.entityModel = null;
  }

  public getUnitName(unitId:number):string {
    const units: UnitModel[] = [];
    this.globalRegistry.systemData.units.forEach(unit=>{
      unit.children && unit.children.length>0 && units.push(...unit.children);
    })
    return units.find(unit=>unit.id===unitId)?.shortName;
  }

  public getEntity(model, value) {
    const valueIds = [value]
    if(model === 'farm') {
      const names = this.globalRegistry?.systemData.farms.filter(item => valueIds.includes(item.id)).map(item => item.name);
      return names 
    } else if(model === 'lot') {
      const names = this.globalRegistry?.systemData.lots.filter(item => valueIds.includes(item.id)).map(item => item.name);
      return names
    } else if(model === 'bill') {
      if(typeof value === 'number') {
        let url = this.stockService.getUrl('docs/' + value);
        this.cacheService.delete(url);
        this.stockService.getDoc(value).subscribe(data => {
          return data.body.results.doc_id
        })
      } else {
        const names = []
        value.forEach(id => {
          let url = this.stockService.getUrl('docs/' + value);
          this.stockService.getDoc(id).subscribe(data => {
            this.cacheService.delete(url);
            names.push(data.body.results.doc_id);
          })
        });
      }
    }
  }

  sanitizeHTML(html): any {
    if(html && html !== '') {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  }

  setValue(value, field) {
    console.log(value);
    this.selectedAttr.value.input = value;
    this.editValuesForm.get('value').setValue(value);
    this.editValue.emit({value: value, type: 'value', validation: field.validation})
  }

  filterEntity(model, keyword) {
    console.log(model + ':' + keyword);
    if(model === 'bill') {
      this.filterBills(keyword);
    } else if(model === 'post') {
      this.filterPosts(keyword);
    } else if(model === 'activity') {
      this.filterActivities(keyword);
    } else if(model === 'ingredient') {
      this.filterIngredients(keyword);
    } else if(model === 'note') {
      this.filterNotes(keyword);
    } else if(model === 'person') {
      this.filterPersons(keyword);
    } else {
      return
    }
  }

  public getLots() {
    this.entities = this.globalRegistry.systemData.lots;
  }

  public getFarms() {
    this.entities = this.globalRegistry.systemData.farms;
  }

  public getEquipments() {
    const url = this.equipmentService.getUrl('equipment/');
        this.cacheService.delete(url+'limit=1000')
        this.equipmentService.getEquipments({limit: 1000}).subscribe(data => {
            console.log(data.model);
            this.entities = data.model;
        })
  }

  public getIngredients() {
    const url = this.stockService.getUrl('ingredients/')
    this.subscriptions.push(
      this.stockService.getIngredients({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
      })
    )
  }

  filterIngredients(value) {
    const url = this.stockService.getUrl('activities/');
    const params = { search: value }
    this.subscriptions.push(this.stockService.getIngredients(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getActivities() {
    const url = this.activityService.getUrl('activities/')
    this.subscriptions.push(
      this.activityService.getActivities({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterActivities(value) {
    const url = this.activityService.getUrl('activities/');
    const params = { search: value }
    this.subscriptions.push(this.activityService.getActivities(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getPersons() {
    const url = this.companyService.getUrl('persons/')
    this.subscriptions.push(
      this.companyService.getPersons({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterPersons(value) {
    const url = this.companyService.getUrl('persons/');
    const params = { search: value }
    this.subscriptions.push(this.companyService.getPersons(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getProducts() {
    console.log(this.selectedAttr);
    const url = this.stockService.getUrl('products/');
    const filters: any = {
      limit: this.limit
    };
  
    let params = this.selectedAttr.value.filter;
    if (params) {
      Object.assign(filters, params);
    }
  
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach(key => queryParams.append(key, filters[key]));
  
    const cacheKey = `${url}?${queryParams.toString()}`;
  
    this.subscriptions.push(
      this.stockService.getProducts(filters).subscribe(data => {
        this.cacheService.delete(cacheKey);
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
        if (this.nextToken) this.getMore = true;
      })
    );
  }  

  filterProducts(value) {
    const url = this.stockService.getUrl('products/');
    const params = { search: value }
    this.subscriptions.push(this.stockService.getProducts(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getNotes() {
    const url = this.notesService.getUrl('')
    this.subscriptions.push(
      this.notesService.getNotes({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data['results'];
        this.nextToken = data['next']
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterNotes(value) {
    const url = this.notesService.getUrl('');
    const params = { search: value }
    this.subscriptions.push(this.notesService.getNotes(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data['next']
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data['results'];
      if(this.nextToken) this.getMore = true
    }))
  }

  public getBills() {
    const url = this.stockService.getUrl('docs/')
    this.subscriptions.push(
      this.stockService.getDocs({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterBills(value) {
    const url = this.stockService.getUrl('');
    const params = { search: value }
    this.subscriptions.push(this.stockService.getDocs(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getPosts() {
    const url = this.farmService.getUrl('posts/')
    this.subscriptions.push(
      this.farmService.getPosts({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterPosts(value) {
    const url = this.farmService.getUrl('posts/');
    const params = { search: value }
    this.subscriptions.push(this.farmService.getPosts(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getSimulations() {
    console.log('get simulations')
  }

  onScroll(model, event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrolledDown(model);
    }
  }

  scrolledDown(model) {
    console.log(model);
    if(model === 'bill') {
      this.scrollBills();
    } else if(model === 'post') {
      this.scrollPosts();
    } else if(model === 'activity') {
      this.scrollActivities();
    } else if(model === 'ingredient') {
      this.scrollIngredients();
    } else if(model === 'note') {
      this.scrollNotes();
    } else if(model === 'person') {
      this.scrollPersons();
    }else {
      return
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrollBills() {
    const url = this.stockService.getUrl('docs/');
    console.log('docs scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.stockService.getDocs(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollPosts() {
    const url = this.farmService.getUrl('posts/');
    console.log('posts scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.farmService.getPosts(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollActivities() {
    const url = this.activityService.getUrl('activities/');
    console.log('activities scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.activityService.getActivities(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollPersons() {
    const url = this.companyService.getUrl('persons/');
    console.log('persons scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.companyService.getPersons(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollIngredients() {
    const url = this.stockService.getUrl('ingredients/');
    console.log('ingredients scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.stockService.getIngredients(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollNotes() {
    const url = this.notesService.getUrl('');
    console.log('notes scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.stockService.getIngredients(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data['results']];
        } else {
          this.entities = data['results'];
        }
        if(data['next'] == null) {
          this.getMore = false;
          return
        } else {
          const url = data['next'].split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  updateCoords(event, field) {
    console.log(event);
    if(event.coordinates.length) {
      this.editValuesForm.get('value').setValue(event.coordinates.toString());
      this.editValue.emit({value: event, type: 'coords', validation: field.validation});
      if(field.validation) {
        this.locationPopup.hideModal();
      }
    }
  }

  updatePolylines(event, field) {
    console.log(event);
    console.log(field);
    if(event && event.length) {
      const pointsString = event.map(entry =>
        entry.points.map(point => `${point.lat},${point.lng}`).join(',')
      ).join(';');
      this.editValuesForm.get('value').setValue(pointsString);
      // this.selectedAttr.value.input = event
      this.editValue.emit({value: event, type: 'polyline', validation: field.validation})
    }
  }

  updatePolygons(event, field) {
    if(event && event.length) {
      const pointsString = event.map(entry =>
        entry.points.map(point => `${point.lat},${point.lng}`).join(',')
      ).join(';');
      this.editValuesForm.get('value').setValue(pointsString);
      // this.selectedAttr.value.input = event
      this.editValue.emit({value: event, type: 'surface', validation: field.validation})
      console.log(this.editValuesForm.value);
    }
  }

  closePopup() {
    this.locationPopup.hideModal();
    console.log(this.editValuesForm.value);
    console.log(this.selectedAttr.value.input);
  }

}
